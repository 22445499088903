import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ForgotPasswordForm } from './components/ForgotPasswordForm';
import { message } from 'antd';

export function ForgotPassword(): React.ReactElement {
  const [t] = useTranslation();
  const onReset = () =>
    message.success('Ссылка для сброса пароля отправлена на ваш E-mail');
  return (
    <React.Fragment>
      <Helmet>
        <title>{t('auth.forgotPassword.title')}</title>
      </Helmet>
      <ForgotPasswordForm onReset={onReset} />
    </React.Fragment>
  );
}
