import axios, { AxiosError } from 'axios';
import { message } from 'antd';
import { AUTH_LOGIN } from 'routes/urls';
import { locale as userLocale } from '../i18n';
import i18n from '../i18n';

if (
  process.env.REACT_APP_MOCK_HTTP &&
  JSON.parse(String(process.env.REACT_APP_MOCK_HTTP))
) {
  require('./http.mocking');
}

let token = localStorage.getItem('authToken');

export const setToken = (token: string | undefined) => {
  if (token === undefined) {
    localStorage.removeItem('authToken');
    return delete axios.defaults.headers.common.Authorization;
  }
  localStorage.setItem('authToken', token);
  axios.defaults.headers.common.Authorization = `bearer ${token}`;
};

axios.interceptors.response.use(undefined, async (error: AxiosError) => {
  if (error.response?.status === 500) {
    await message.error(i18n.t('errors.500'));
  } else if (error.response?.status === 429) {
    await message.error(i18n.t('errors.429'));
  } else if (error.response?.status === 401) {
    if (error.response.config.url !== AUTH_LOGIN) {
      message.error(i18n.t('errors.session_expired')).then(() => {});
    }

    if (window.location.pathname !== AUTH_LOGIN) {
      window.history.pushState({}, '', AUTH_LOGIN);
    }
  }

  throw error;
});

axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || 'http://localhost:3000';

// prettier-ignore
axios.defaults.headers.common = token && {
  "Authorization": `bearer ${token}`
};
axios.defaults.headers.common = {
  ...axios.defaults.headers.common,
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'User-Locale': userLocale(),
};

export default axios;
