import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, Register } from './types';
import {
  getUserCountry,
  loginAction,
  logoutAction,
  registerAction,
  validateTokenAction,
  verifySessionAction,
} from './actions';
import { setToken } from 'api/http.client';
import { getCodeByCountry } from 'utils/phone-mask';
import { toggleStateLoader } from '../../helpers';

const sessionId = sessionStorage.getItem('sessionId') || undefined;
const authToken = localStorage.getItem('authToken') || undefined;

const initialState: AuthState = {
  sessionId,
  authToken,
  registeringUser: null,
  isLoginLoading: false,
  isSessionLoading: false,
  isRegisterLoading: false,
  isResetPassLoading: false,
  isForgotPassLoading: false,
  isVerificationLoading: false,
  selectedPhoneCode: '+38',
  isTokenVerified: false,
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    toggleStateLoader,
    setIsTokenVerified(state, { payload }: PayloadAction<boolean>) {
      state.isTokenVerified = payload;
    },
    setSessionId(state, { payload }: PayloadAction<string | undefined>) {
      state.sessionId = payload;
    },
    setRegisteringUser(state, { payload }: PayloadAction<Register | null>) {
      state.registeringUser = payload;
    },
    setSelectedCode(state, { payload }: PayloadAction<string>) {
      state.selectedPhoneCode = payload;
    },
  },
  extraReducers: {
    [loginAction.success.toString()]: (
      state,
      { payload }: PayloadAction<{ token: string }>,
    ) => {
      state.authToken = payload.token;
      setToken(payload.token);
    },
    [logoutAction.success.toString()]: state => {
      state.authToken = undefined;
      state.sessionId = undefined;
      setToken(undefined);
    },
    [registerAction.request.toString()]: (
      state,
      { payload }: PayloadAction<Register>,
    ) => {
      state.registeringUser = payload;
    },
    [registerAction.success.toString()]: (
      state,
      { payload: { sessionId } }: PayloadAction<{ sessionId: string }>,
    ) => {
      state.sessionId = sessionId;
      sessionStorage.setItem('sessionId', sessionId);
    },
    [verifySessionAction.success.toString()]: (
      state,
      { payload }: PayloadAction<{ token: string }>,
    ) => {
      state.authToken = payload.token;
      setToken(payload.token);
    },
    [validateTokenAction.failure.toString()]: state => {
      if (state.authToken) {
        state.authToken = undefined;
      }
      setToken(undefined);
    },
    [getUserCountry.success.toString()]: (
      state,
      { payload: { country_code } }: PayloadAction<{ country_code: string }>,
    ) => {
      state.selectedPhoneCode = getCodeByCountry(country_code);
    },
  },
});

export const { actions } = slice;
