import * as yup from 'yup';
import { getMaskLength } from 'utils/phone-mask';
import { TFunction } from 'react-i18next';

export function rules(t: TFunction, phoneCode: string) {
  return yup.object().shape({
    fullName: yup.string().required().label(t('auth.registerForm.full_name')),
    email: yup.string().required().email().label(t('auth.registerForm.email')),
    phoneNumber: yup
      .string()
      .transform((value: string) => {
        return `+${value.replace(/\D+/g, '')}`;
      })
      .required()
      .length(phoneCode.trimEnd().length + getMaskLength(phoneCode))
      .label(t('auth.registerForm.phone')),
  });
}
