import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.locale('ru');

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});
dayjs.updateLocale('ru', {
  relativeTime: {
    future: 'через %s',
    past: '%s назад',
    s: 'несколько секунд',
    m: 'минуту',
    mm: '%d минут',
    h: 'час',
    hh: '%d часов',
    d: 'день',
    dd: '%d дней',
    M: 'месяц',
    MM: '%d месяцев',
    y: 'год',
    yy: '%d годов',
  },
});
dayjs.updateLocale('ua', {
  relativeTime: {
    future: 'через %s',
    past: '%s назад',
    s: 'декілька секунд',
    m: 'хвилину',
    mm: '%d хвилин',
    h: 'годину',
    hh: '%d годин',
    d: 'день',
    dd: '%d днів',
    M: 'місяць',
    MM: '%d місяців',
    y: 'рік',
    yy: '%d років',
  },
});

export const formatDate = (
  value: string | null,
  defaultValue: string | undefined = '-',
) => (value ? dayjs(value).format('DD.MM.YYYY') : defaultValue);

export const dateDiff = (value: string) => dayjs().to(dayjs(value));
