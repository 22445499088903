import { fork } from 'redux-saga/effects';
import { authSaga } from './slices/auth/saga';
import { instanceSaga } from './slices/instance/saga';
import { accountSaga } from './slices/account/saga';

export default function* root() {
  yield fork(authSaga);
  yield fork(instanceSaga);
  yield fork(accountSaga);
}
