import React from 'react';

export const withAnimation = (
  WrappedComponent: React.ElementType,
): React.FunctionComponent => {
  return () => {
    return (
      <div className="animate__animated animate__animate__zoomIn">
        <WrappedComponent />
      </div>
    );
  };
};
