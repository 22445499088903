import React from 'react';
import { Button, Modal, Input, Form as AntForm } from 'antd';
import { capitalize } from 'lodash';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { TFunction, useTranslation } from 'react-i18next';
import Table from 'components/Table';
import { Pagination, TableColumn } from 'components/Table/Table';
import { AccountInstance, Instance } from 'store/slices/instance/types';
import { useSelector } from 'react-redux';
import { RootState } from 'types/RootState';
import { formatMoney } from 'helpers/account';
import { dateDiff } from 'helpers/date';

interface Props {
  loading: boolean;
  instances: Instance[];
  pagination: Pagination;
}

const confirmationDeleteModal = (
  t: TFunction,
  domain: string,
  setInput: (value: string) => void,
  onOk: () => void,
) => {
  const content = (
    <span>
      <span
        dangerouslySetInnerHTML={{
          __html: t('app.account.deleting.confirmation', {
            domain,
          }),
        }}
      />
      <span>
        <AntForm.Item style={{ marginTop: '10px' }}>
          <small
            dangerouslySetInnerHTML={{
              __html: t('app.account.deleting.input_message', {
                domain,
              }),
            }}
          />
          <Input
            style={{ marginTop: '10px' }}
            onChange={({ target: { value } }) => setInput(value)}
          />
        </AntForm.Item>
      </span>
    </span>
  );

  return Modal.confirm({
    title: t('base.important'),
    okText: t('base.yes'),
    cancelText: t('base.cancel'),
    // @ts-ignore
    icon: <ExclamationCircleOutlined />,
    content,
    onOk,
    okButtonProps: { disabled: true },
  });
};

export const ActiveInstancesTable = ({
  loading,
  instances,
  onDelete,
  pagination,
}: Props & { onDelete: (instance: Instance) => void }): React.ReactElement => {
  const [t] = useTranslation();
  const accountId = String(
    useSelector((state: RootState) => state.account.account?.id),
  );
  const handleDelete = (instance: Instance) => {
    const modal = confirmationDeleteModal(
      t,
      instance.domain,
      value =>
        modal.update({
          okButtonProps: { disabled: value !== instance.domain },
        }),
      () => {
        onDelete(instance);
      },
    );
  };
  const handleLogin = ({ domain }: Instance) => {
    window.open(
      `https://${
        domain + process.env.REACT_APP_TENANT_HOST
      }/auth/login-via-account`,
      '_blank',
    );
  };
  const isOwner = (instance: Instance): boolean => {
    const isOwner = (instance.accountInstances || []).some(
      ({ account, isOwner }) => {
        return account.id === accountId && isOwner;
      },
    );
    return Boolean(isOwner);
  };

  const getAccountInstance = (
    instance: Instance,
  ): AccountInstance | undefined => {
    return instance.accountInstances?.find(({ account }) => {
      return account.id === accountId;
    });
  };

  const renderRole = (instance: Instance): React.ReactFragment | string => {
    if (!instance.installed) {
      return '-';
    }
    const { color: background, name } = getAccountInstance(instance)?.role as {
      name: string;
      color: string;
    };

    return (
      <span className="crm-role" style={{ background }}>
        {name}
      </span>
    );
  };

  const columns: TableColumn[] = [
    {
      title: t('app.account.instances.name'),
      dataIndex: 'domain',
      key: 'domain',
      render: (row: Instance) => (
        <React.Fragment>
          <span className="crm-name">{capitalize(row.domain)}</span>
          <span className="crm-url">
            {row.domain + process.env.REACT_APP_TENANT_HOST}
          </span>
          {isOwner(row) && (
            <Button
              size="small"
              className="icon margin-left-one"
              onClick={() => handleDelete(row)}
              title={t('app.account.instances.remove')}
            >
              <i className="key-icon key-icon--trash" />
            </Button>
          )}
        </React.Fragment>
      ),
    },
    {
      title: t('app.account.instances.role'),
      dataIndex: 'role',
      key: 'role',
      render: (row: Instance) => (
        <React.Fragment>{row.installed ? renderRole(row) : '-'}</React.Fragment>
      ),
    },
    {
      title: t('app.account.instances.balance'),
      dataIndex: 'balance',
      key: 'balance',
      render: (row: Instance) => (
        <React.Fragment>
          {isOwner(row) ? (
            row.isFree ? (
              <span className={'text-red'}>FREE</span>
            ) : (
              <div className={Number(row.balance) < 0 ? 'text-red' : ''}>
                {formatMoney(Number(row.balance))}
              </div>
            )
          ) : (
            '-'
          )}
        </React.Fragment>
      ),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      tdStyle: { textAlign: 'right' },
      render: (row: Instance) => (
        <React.Fragment>
          {row.installed ? (
            <>
              <Button
                type="primary"
                className="margin-left-one"
                onClick={() => handleLogin(row)}
              >
                {t('app.account.instances.enter')}
              </Button>
            </>
          ) : (
            <LoadingOutlined />
          )}
        </React.Fragment>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={instances}
      loading={loading}
      pagination={pagination}
    />
  );
};

export const DeleteScheduledTable = ({
  loading,
  instances,
  pagination,
  onRevert,
}: Props & { onRevert: (instance) => void }): React.ReactElement => {
  const [t] = useTranslation();
  const columns: TableColumn[] = [
    {
      title: t('app.account.instances.name'),
      dataIndex: 'domain',
      key: 'domain',
      render: (row: Instance) => (
        <React.Fragment>
          <span className="crm-name">{capitalize(row.domain)}</span>
          <span className="crm-url">{row.domain}.keycrm.app</span>
        </React.Fragment>
      ),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      tdStyle: { textAlign: 'right' },
      render: (value: Instance) => (
        <React.Fragment>
          <span className="crm-timeleft">
            {dateDiff(String(value.scheduledDeleteAt))}
          </span>
          <Button className="margin-left-one" onClick={() => onRevert(value)}>
            {t('base.cancel')}
          </Button>
        </React.Fragment>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      data={instances}
      loading={loading}
      pagination={pagination}
    />
  );
};
