import React, { useState } from 'react';
import { Input } from 'antd';
import './style.scss';

interface Props {
  length: number;
  onChange: (value: string) => void;
}

interface InputChange {
  onChange: (value: string, inputIndex: number) => void;
}

const renterInput = (
  key: number,
  { onChange }: InputChange,
): React.ReactElement => {
  const handleInputChange = (value: string) => {
    value.length && focusNextInput(key);
    onChange(value, key);
  };

  return (
    <Input
      key={key}
      size="large"
      maxLength={1}
      className="verification-input__input"
      onFocus={event => {
        if (event.target.value) {
          event.target.select();
        }
      }}
      onKeyPress={event => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      onChange={({ target: { value } }) => handleInputChange(value)}
    />
  );
};

const renderElements = (
  length: number,
  { onChange }: InputChange,
): React.ReactElement[] => {
  return Array.from<number>({ length }).map((value, index) => {
    return renterInput(index, { onChange });
  });
};

const focusNextInput = (currentIndex: number) => {
  const elements = document.getElementsByClassName(
    'verification-input__input',
  ) as HTMLCollection;
  const nextElement = elements[currentIndex + 1] as HTMLInputElement;
  if (nextElement !== undefined) {
    nextElement.focus();
  }
};

export function VerificationInput({
  length,
  onChange,
}: Props): React.ReactElement {
  const [value, setValue] = useState<string[]>([]);

  const onValueChange = (e, i) => {
    value[i] = e;
    setValue(value);
    const newValue = value.join('');
    onChange(newValue);
  };

  return (
    <div className="verification-input">
      <div className="verification-input__container">
        {renderElements(length, {
          onChange: onValueChange,
        })}
      </div>
    </div>
  );
}
