import React from 'react';
import Lottie from 'react-lottie-segments';
import scene from './success.scene.json';
import { LoaderProps } from '../types';

export const SuccessLoader = (props: LoaderProps): JSX.Element => {
  return (
    <Lottie
      eventListeners={[
        {
          eventName: 'complete',
          callback: props.onAnimationComplete,
        },
      ]}
      options={{
        loop: false,
        autoplay: true,
        animationData: scene,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
    />
  );
};
