import React from 'react';
import { Tag } from 'antd';
import Table from 'components/Table';
import { Pagination, TableColumn } from 'components/Table/Table';
import { ReferralPayout } from 'store/slices/account/types';
import { formatMoney } from 'helpers/account';
import { formatDate } from 'helpers/date';
import { useTranslation } from 'react-i18next';

interface Props {
  payouts: ReferralPayout[];
  loading: boolean;
  pagination: Pagination;
}

export function PayoutsTable({
  payouts,
  loading,
  pagination,
}: Props): React.ReactElement {
  const [t] = useTranslation();

  const renderState = ({ state }): React.ReactElement => {
    const map = {
      pending: {
        color: '#FFAD33',
        title: 'на рассмотрении',
      },
      paid: {
        color: '#1CC052',
        title: 'оплачено',
      },
      declined: {
        color: '#FF5533',
        title: 'отменено',
      },
    }[state];

    return <Tag color={map.color}>{map.title}</Tag>;
  };

  const columns: TableColumn[] = [
    {
      dataIndex: 'createdAt',
      key: 'createdAt',
      title: t('app.account.payouts.table_date'),
      formatValue: value => formatDate(value),
    },
    {
      key: 'amount',
      title: t('app.account.payouts.table_amount'),
      dataIndex: 'amount',
      formatValue: value => formatMoney(value),
    },
    {
      dataIndex: 'comment',
      key: 'comment',
      title: t('app.account.payouts.table_comment'),
    },
    {
      key: 'state',
      title: t('app.account.payouts.table_status'),
      dataIndex: 'state',
      render: renderState,
    },
    {
      key: 'paidAt',
      dataIndex: 'paidAt',
      title: t('app.account.payouts.table_payout_date'),
      formatValue: value => formatDate(value),
    },
  ];
  return (
    <Table
      columns={columns}
      data={payouts}
      loading={loading}
      pagination={pagination}
    />
  );
}
