import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { NewInstanceForm } from 'components/Form/NewInstanceForm';
import { RootState } from 'types/RootState';
import { Instance } from 'store/slices/instance/types';
import { useTranslation } from 'react-i18next';

interface Props {
  isModalVisible: boolean;
  handleOk: (instance: Instance) => void;
  handleCancel: () => void;
}

export function CreateInstanceModal({
  isModalVisible,
  handleCancel,
  handleOk,
}: Props): React.ReactElement {
  const [t] = useTranslation();
  const ref = React.useRef<any>();
  const { creatingLoading } = useSelector((state: RootState) => state.instance);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(
    false,
  );

  const handleConfirm = () => {
    ref.current.toggleConfirmation();
  };

  const handleCreated = (instance: Instance) => {
    handleOk(instance);
  };

  const handleClose = () => {
    ref.current.reset();
  };

  return (
    <Modal
      title={t('app.account.create_new.title')}
      visible={isModalVisible}
      onOk={handleConfirm}
      onCancel={handleCancel}
      afterClose={handleClose}
      okText={t('app.account.create_new.button_text')}
      cancelText={t('base.cancel')}
      okButtonProps={{
        className: 'ant-btn-block',
        disabled: isButtonDisabled,
        loading: creatingLoading,
      }}
    >
      <NewInstanceForm
        ref={ref}
        hintTitle={t('app.account.create_new.subtitle')}
        onSuccess={handleCreated}
        onToggleCheckbox={value => setIsButtonDisabled(!value)}
      />
    </Modal>
  );
}
