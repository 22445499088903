import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/RootState';
import { useTranslation } from 'react-i18next';
import { ReferralBanner } from './components/ReferralBanner/ReferralBanner';
import { AccountNavigation } from './components/Navigation/AccountNavigation';
import { Logo } from './components/Logo/Logo';
import { APP_ACCOUNT_SETTINGS, AUTH_LOGIN } from 'routes/urls';
import { logoutAction } from 'store/slices/auth/actions';
import './styles.scss';

interface Props {
  children: React.ReactElement;
}

export function Account({ children }: Props): React.ReactElement {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const account = useSelector((state: RootState) => state.account.account);
  const hasReferral = account?.refNumber !== null;

  const logout = () => {
    dispatch(logoutAction.request()).then(() => {
      window.location.href = AUTH_LOGIN;
    });
  };
  return (
    <div className={['account', hasReferral && 'partner'].join(' ')}>
      <div className="account__header">
        <Logo />
        <div>
          <Link
            to={{
              pathname: APP_ACCOUNT_SETTINGS,
            }}
          >
            {account?.email}
          </Link>
          <Button
            htmlType="button"
            onClick={logout}
            className="icon"
            title={t('app.account.navigation.logout')}
            style={{
              marginLeft: '15px',
              cursor: 'pointer',
              verticalAlign: 'middle',
            }}
          >
            <i className="key-icon key-icon--logout" />
          </Button>
        </div>
      </div>
      <div className="account__main">
        {hasReferral && <AccountNavigation />}
        <div className="account__right">
          {children}
          <ReferralBanner />
        </div>
      </div>
    </div>
  );
}
