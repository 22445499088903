import React from 'react';
import Lottie from 'react-lottie-segments';
import scene from './animations/404.scene.json';
import './styles.scss';

export function PageNotFound(): React.ReactElement {
  return (
    <div className="page-not-found">
      <div style={{ maxWidth: '500px' }}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: scene,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
        />
      </div>
    </div>
  );
}
