import React from 'react';
import { Button, Form as AntForm, Input, message } from 'antd';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { APP_ACCOUNT_INDEX } from 'routes/urls';
import * as yup from 'yup';
import * as validation from '../../../helpers/validation';
import { useTranslation } from 'react-i18next';
import { updatePasswordAction } from '../../../store/slices/account/actions';
import { useDispatch, useSelector } from 'react-redux';
import { setResponseValidation } from 'helpers/validation';
import { RootState } from '../../../types/RootState';

export function PasswordsForm(): React.ReactElement {
  const history = useHistory();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state.account.isUpdatingPassword,
  );
  const rules = yup.object().shape({
    oldPassword: yup
      .string()
      .required()
      .min(6)
      .label(t('app.account.settings.oldPassword')),
    password: yup
      .string()
      .required()
      .min(6)
      .label(t('app.account.settings.password')),
    passwordConfirmation: yup
      .string()
      .required()
      .min(6)
      .oneOf(
        [yup.ref('password')],
        t('app.account.settings.passwordConfirmationFailed'),
      )
      .label(t('app.account.settings.passwordConfirmation')),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(rules),
    defaultValues: {
      oldPassword: '',
      password: '',
      passwordConfirmation: '',
    },
  });
  const onFormSubmit = value => {
    dispatch(updatePasswordAction.request(value))
      .then(() => {
        message.success('Пароль успешно обновлен');
        reset();
      })
      .catch(e => {
        if (e.response.status === 422) {
          setResponseValidation(setError, e.response.data.errors);
        }
      });
  };
  return (
    <React.Fragment>
      <h3>{t('app.account.settings.title_password')}</h3>

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="box__form ant-form ant-form-vertical">
          <AntForm.Item
            label={t('app.account.settings.oldPassword')}
            help={validation.validationError(errors.oldPassword)}
            validateStatus={validation.validationStatus(errors.oldPassword)}
          >
            <Controller
              name="oldPassword"
              control={control}
              render={({ field }) => (
                <Input.Password
                  {...field}
                  id="oldPassword"
                  allowClear={true}
                  autoComplete={'off'}
                />
              )}
            />
          </AntForm.Item>
          <AntForm.Item
            label={t('app.account.settings.password')}
            help={validation.validationError(errors.password)}
            validateStatus={validation.validationStatus(errors.password)}
          >
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input.Password
                  {...field}
                  id="password"
                  allowClear={true}
                  autoComplete={'off'}
                />
              )}
            />
          </AntForm.Item>
          <AntForm.Item
            label={t('app.account.settings.passwordConfirmation')}
            help={validation.validationError(errors.passwordConfirmation)}
            validateStatus={validation.validationStatus(
              errors.passwordConfirmation,
            )}
          >
            <Controller
              name="passwordConfirmation"
              control={control}
              render={({ field }) => (
                <Input.Password
                  {...field}
                  id="passwordConfirmation"
                  allowClear={true}
                  autoComplete={'off'}
                />
              )}
            />
          </AntForm.Item>
          <Button block type="primary" htmlType="submit" loading={loading}>
            {t('base.save')}
          </Button>
          <div className="margin-top-one margin-bottom-one">
            <Button
              block
              type="link"
              htmlType="button"
              onClick={() => history.push(APP_ACCOUNT_INDEX)}
            >
              {t('base.back_to_main')}
            </Button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
