import { useState } from 'react';

export function useLoading(defaultValue: boolean = false) {
  const [loading, setLoading] = useState(defaultValue);
  const toggleLoading = () => setLoading(loading => !loading);

  return {
    loading,
    toggleLoading,
  };
}
