import React from 'react';
import { Layout } from 'antd';
import { ScaleLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/RootState';
import './styles.scss';
import { getUserCountry } from '../../../store/slices/auth/actions';

interface Props {
  children: React.ReactNode;
}

export const Base = (props: Props): React.ReactElement => {
  const { isVerificationLoading } = useSelector(
    (state: RootState) => state.auth,
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getUserCountry.request()).then(() => {});
  }, [dispatch]);
  return (
    <Layout style={{ height: '100vh' }}>
      <Layout.Content>
        <div>
          {isVerificationLoading ? (
            <div
              style={{
                display: 'flex',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ScaleLoader
                height={35}
                width={4}
                color={'#00A6F2'}
                loading={true}
              />
            </div>
          ) : (
            props.children
          )}
        </div>
      </Layout.Content>
      {/* <Footer style={{ textAlign: 'center' }}>KeyCRM</Footer> */}
    </Layout>
  );
};
