import React from 'react';
import * as urls from 'routes/urls';
import * as pages from 'pages';
import { withAnimation } from 'pages/withAnimation';
import { PrivateRoute } from './PrivateRoute';
import { AccountRoutes } from './AccountRoutes';

export const AppRoutes = (): React.ReactElement => {
  return (
    <React.Fragment>
      <PrivateRoute
        exact={true}
        path={urls.INDEX}
        component={pages.IndexPage}
      />
      <PrivateRoute
        exact={true}
        path={urls.APP_NEW_INSTANCE}
        component={withAnimation(pages.NewInstance)}
      />
      <PrivateRoute
        exact={true}
        path={urls.APP_NEW_INSTANCE_PROGRESS}
        component={withAnimation(pages.CreatingProgress)}
      />
      <AccountRoutes />
    </React.Fragment>
  );
};
