const triggerEvents =
  process.env.REACT_APP_TRIGGER_EVENTS &&
  JSON.parse(String(process.env.REACT_APP_TRIGGER_EVENTS));

export default {
  check() {
    console.log('events: check');
    if (!triggerEvents) {
      return;
    }
    try {
      window['fbq'] && window['fbq']('track', 'InitiateCheckout');
    } catch (error) {
      console.error(error);
    }

    try {
      window['ga'] &&
        window['ga'].getAll &&
        window['ga'](
          window['ga'].getAll()[0].get('name') + '.send',
          'event',
          'Registration',
          'InitiateCheckout',
        );
      window['gtag'] && window['gtag']('event', 'Reg_Step1_ClickTrialButton');
    } catch (error) {
      console.error(error);
    }
  },
  identity({ phoneNumber, email }: { phoneNumber: string; email: string }) {
    console.log('events: identity');
    if (!triggerEvents) {
      return;
    }
  },
  phoneNumberConfirmation() {
    console.log('events: phoneNumberConfirmation');
    if (!triggerEvents) {
      return;
    }
  },
  registered(domain: string) {
    console.log('events: registered');
    if (!triggerEvents) {
      return;
    }
  },
  verified() {
    console.log('events: verified');
    if (!triggerEvents) {
      return;
    }
    try {
      window['fbq'] && window['fbq']('track', 'Lead');
    } catch (error) {
      console.log(error);
    }
    //@ts-ignore
    try {
      window['ga'] &&
        window['ga'].getAll &&
        window['ga'](
          window['ga'].getAll()[0].get('name') + '.send',
          'event',
          'Registration',
          'Lead',
        );
      window['gtag'] && window['gtag']('event', 'Reg_Step2_Lead');
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    console.log('events: created');
    if (!triggerEvents) {
      return;
    }
    try {
      window['fbq'] && window['fbq']('track', 'StartTrial');
    } catch (error) {
      console.log(error);
    }
    try {
      window['ga'] &&
        window['ga'].getAll &&
        window['ga'](
          window['ga'].getAll()[0].get('name') + '.send',
          'event',
          'Registration',
          'StartTrial',
        );
      window['gtag'] && window['gtag']('event', 'Reg_Step3_StartTrial');
    } catch (error) {
      console.log(error);
    }
  },
};
