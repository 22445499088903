import React from 'react';
import { PrivateRoute } from './PrivateRoute';
import * as urls from 'routes/urls';
import { Account as Layout } from '../Layouts';
import * as pages from 'pages/Account';
import { MercureProvider } from '../Providers/MercureProvider';

const wrapLayout = (
  WrappedComponent: React.ElementType,
): React.FunctionComponent => {
  return () => {
    return (
      <Layout>
        <WrappedComponent />
      </Layout>
    );
  };
};

export const AccountRoutes = (): React.ReactElement => {
  return (
    <React.Fragment>
      <MercureProvider>
        <React.Fragment>
          <PrivateRoute
            exact={true}
            path={urls.APP_ACCOUNT_INDEX}
            component={wrapLayout(pages.Dashboard)}
          />
          <PrivateRoute
            exact={true}
            path={urls.APP_ACCOUNT_SETTINGS}
            component={wrapLayout(pages.Settings)}
          />
          <PrivateRoute
            exact={true}
            path={urls.APP_ACCOUNT_REFERRALS}
            component={wrapLayout(pages.Referrals)}
          />
          <PrivateRoute
            exact={true}
            path={urls.APP_ACCOUNT_PAYOUTS}
            component={wrapLayout(pages.Payouts)}
          />
          <PrivateRoute
            exact={true}
            path={urls.APP_ACCOUNT_STATISTIC}
            component={wrapLayout(pages.Statistics)}
          />
          <PrivateRoute
            exact={true}
            path={urls.APP_ACCOUNT_RULES}
            component={wrapLayout(pages.Rules)}
          />
        </React.Fragment>
      </MercureProvider>
    </React.Fragment>
  );
};
