import { Account } from '../store/slices/account/types';
import parsePhoneNumber from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js/types';

export const formatPhone = (account: Account) => {
  const phone = parsePhoneNumber('+' + String(account?.phoneNumber), {
    defaultCountry: account?.country as CountryCode,
  });
  return phone ? phone.format('INTERNATIONAL') : account.phoneNumber;
};

export const formatMoney = (value: number | null) =>
  `${(value || 0).toFixed(2)} $`;
