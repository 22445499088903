import httpClient from './http.client';
import { AxiosResponse } from 'axios';
import { Register, VerifySession } from 'store/slices/auth/types';
import * as paths from './paths';
import { pathToRoute } from 'utils/path-to-route';

const passwordResetLink = (token: string, email: string) => {
  return (
    pathToRoute(paths.AUTH_RESET_PASSWORD, {
      token,
    }) +
    '?email=' +
    email
  );
};

export const login = (params: {
  email: string;
  password: string;
}): Promise<AxiosResponse> =>
  httpClient.post(paths.AUTH_LOGIN, params, { withCredentials: true });

export const logout = (): Promise<AxiosResponse> =>
  httpClient.post(paths.AUTH_LOGOUT, {}, { withCredentials: true });

export const validateTokenRequest = (): Promise<AxiosResponse> =>
  httpClient.get(paths.ACCOUNT_GET);

export const registerRequest = (params: Register): Promise<AxiosResponse> =>
  httpClient.post(paths.AUTH_REGISTER, params);

export const forgotPassword = (email: string): Promise<AxiosResponse> =>
  httpClient.post(paths.AUTH_FORGOT_PASSWORD, { email });

export const resetPassword = (
  token: string,
  email: string,
  password: string,
  passwordConfirmation: string,
): Promise<AxiosResponse> => {
  return httpClient.post(passwordResetLink(token, email), {
    password,
    passwordConfirmation,
  });
};

export const resetPasswordCheck = (
  token: string,
  email: string,
): Promise<AxiosResponse> => {
  return httpClient.get(passwordResetLink(token, email));
};

export const verifySession = (
  params: VerifySession,
): Promise<AxiosResponse> => {
  const url: string = pathToRoute(paths.AUTH_VERIFY_SESSION, {
    id: params.sessionId,
  });
  return httpClient.post(url, {
    verificationCode: params.verificationCode,
  });
};

export const getUserCountry = () => {
  const client = httpClient.create();
  // it throws CORS error
  delete client.defaults.headers.common['User-Locale'];
  return client.get(
    'https://ipgeolocation.abstractapi.com/v1/?api_key=a480e55b57e34dadae8283a04d339d9a',
  );
};
