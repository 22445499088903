import React from 'react';
import { Link } from 'react-router-dom';
import { APP_ACCOUNT_INDEX } from 'routes/urls';

export function Logo(): React.ReactElement {
  return (
    <Link
      to={{
        pathname: APP_ACCOUNT_INDEX,
      }}
      className="logo"
    >
      KeyCRM Logo
    </Link>
  );
}
