import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form as AntForm, Input, message } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import AccountBox from 'components/Containers/AccountBox';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormTitle } from 'components/Form/FormTitle/FormTitle';
import * as validation from 'helpers/validation';
import { AUTH_LOGIN } from 'routes/urls';
import { resetPasswordAction } from 'store/slices/auth/actions';
import { RootState } from 'types/RootState';
import { rules } from './rules';

interface Props {
  onReset(): void;
  token: string;
  email: string;
}

export function ResetPasswordForm({ onReset, token, email }: Props) {
  const [t] = useTranslation();
  const validationRules = rules(t);
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state.auth.isResetPassLoading,
  );
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationRules),
  });

  const onSubmit = value => {
    dispatch(
      resetPasswordAction.request({
        token,
        email,
        ...value,
      }),
    )
      .then(() => {
        reset();
        onReset();
      })
      .catch(e => {
        if (e.response.status === 400) {
          message.error(t('auth.forgotPassword.resetFailed'));
        }
      });
  };

  return (
    <AccountBox image="laptop">
      <form
        className={'ant-form ant-form-vertical accountbox__form'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="accountbox__form-inner">
          <FormTitle>{t('auth.resetPassword.title')}</FormTitle>
          <AntForm.Item
            label={t('auth.resetPassword.password')}
            help={validation.validationError(errors.password)}
            validateStatus={validation.validationStatus(errors.password)}
          >
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input.Password
                  {...field}
                  id="password"
                  allowClear={true}
                  autoComplete={'off'}
                />
              )}
            />
          </AntForm.Item>
          <AntForm.Item
            label={t('auth.resetPassword.passwordConfirmation')}
            help={validation.validationError(errors.passwordConfirmation)}
            validateStatus={validation.validationStatus(
              errors.passwordConfirmation,
            )}
          >
            <Controller
              name="passwordConfirmation"
              control={control}
              render={({ field }) => (
                <Input.Password
                  {...field}
                  id="passwordConfirmation"
                  allowClear={true}
                  autoComplete={'off'}
                />
              )}
            />
          </AntForm.Item>

          <Button
            block
            type="primary"
            loading={loading}
            htmlType="submit"
            className="form-button"
          >
            {t('auth.resetPassword.save')}
          </Button>
          <div className="margin-top-one">
            <span style={{ fontSize: '12px' }}>
              <Link
                to={{
                  pathname: AUTH_LOGIN,
                }}
              >
                {t('auth.forgotPassword.toLogin')}
              </Link>
            </span>
          </div>
        </div>
      </form>
    </AccountBox>
  );
}
