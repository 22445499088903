import * as yup from 'yup';
import { TFunction } from 'react-i18next';

export function rules(t: TFunction) {
  return yup.object().shape({
    password: yup
      .string()
      .required()
      .min(6)
      .label(t('auth.resetPassword.password')),
    passwordConfirmation: yup
      .string()
      .required()
      .min(6)
      .oneOf(
        [yup.ref('password')],
        t('auth.resetPassword.passwordConfirmationFailed'),
      )
      .label(t('auth.resetPassword.passwordConfirmation')),
  });
}
