import { createPromiseAction } from 'redux-saga-promise-actions';
import { ReferralAccrual, ReferralPayout, UpdatePassword } from './types';
import { PaginationRequest, PaginationResponse } from '../global/types';

export const updatePasswordAction = createPromiseAction('UPDATE_PASSWORD')<
  UpdatePassword,
  any,
  any
>();

export const activateReferralAction = createPromiseAction('ACTIVATE_REFERRAL')<
  undefined,
  { refNumber: string },
  any
>();

export const getReferralsAction = createPromiseAction('GET_REFERRALS')<
  PaginationRequest,
  PaginationResponse<Account>,
  any
>();

export const getReferralAccrualsAction = createPromiseAction(
  'GET_REFERRAL_ACCRUALS',
)<PaginationRequest, PaginationResponse<ReferralAccrual>, any>();

export const getReferralPayoutsAction = createPromiseAction(
  'GET_REFERRAL_PAYOUTS',
)<PaginationRequest, PaginationResponse<ReferralPayout>, any>();

export const createReferralPayoutAction = createPromiseAction(
  'CREATE_REFERRAL_PAYOUT',
)<{ comment: string; amount: number }, ReferralPayout, any>();
