import React from 'react';
import './styles.scss';

interface Props {
  children: React.ReactElement;
  showImage?: boolean;
  image?: string;
  className?: string;
}

export const AccountBox = ({
  children,
  showImage = true,
  image = '',
  className = '',
}: Props): React.ReactElement => (
  <div className={'accountbox auth_layout ' + className}>
    {showImage && (
      <div className={'accountbox__image ' + image}>
        <a
          target="_blank"
          className="logo"
          href="https://keycrm.app"
          rel="noopener noreferrer"
        >
          .
        </a>
        <div className="copy">
          &copy; KeyCRM 2019-{new Date().getFullYear()}
        </div>
      </div>
    )}
    {children}
  </div>
);
