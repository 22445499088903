import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Modal } from 'antd';
import { RootState } from 'types/RootState';
import { Instance } from 'store/slices/instance/types';
import { LinkInstanceForm } from 'components/Form/LinkInstanceForm';
import { linkInstanceAction } from 'store/slices/instance/actions';
import { useTranslation } from 'react-i18next';

interface Props {
  isModalVisible: boolean;
  handleOk: (instance: Instance) => void;
  handleCancel: () => void;
}

export function LinkInstanceModal({
  isModalVisible,
  handleCancel,
  handleOk,
}: Props): React.ReactElement {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState<string>('');
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const { isLinkingLoading: loading } = useSelector(
    (state: RootState) => state.instance,
  );
  const handleConfirm = () => {
    if (!value.length) {
      return setIsValid(false);
    }
    setIsValid(true);
    dispatch(linkInstanceAction.request({ code: value }))
      .then(instance => {
        message.success(t('app.account.add_existing.msg_success'));
        handleOk(instance);
        setValue('');
      })
      .catch(e => {
        if (e.response.data.message.indexOf('already associated') !== -1) {
          return message.error(t('app.account.add_existing.msg_exists'));
        }
        message.error(t('app.account.add_existing.msg_error'));
      });
  };

  return (
    <Modal
      title={t('app.account.add_existing.title')}
      visible={isModalVisible}
      onOk={handleConfirm}
      onCancel={handleCancel}
      okText={t('base.add')}
      cancelText={t('base.cancel')}
      okButtonProps={{
        loading,
        className: 'ant-btn-block',
      }}
    >
      <LinkInstanceForm onChange={setValue} isValid={isValid} />
    </Modal>
  );
}
