import React from 'react';
import { Button, Form as AntForm, Input } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from 'types/RootState';
import { useHistory } from 'react-router';
import { APP_ACCOUNT_RULES } from 'routes/urls';

export function ReferralBanner(): React.ReactElement {
  const [t] = useTranslation();
  const history = useHistory();
  const { account } = useSelector((state: RootState) => state.account);

  const refUrl = React.useMemo(() => {
    return account?.refNumber
      ? `https://ua.keycrm.app?join=${account.refNumber}`
      : null;
  }, [account?.refNumber]);

  return (
    <React.Fragment>
      <div className="box promo">
        <div className="promo__left">
          <span className="promo__text">{t('app.referralBanner.promo')}</span>
          <Button
            ghost
            className="margin-top-two"
            onClick={() => history.push(APP_ACCOUNT_RULES)}
          >
            {t('app.referralBanner.read_more')}
          </Button>
        </div>
        <div className="promo__right ant-form ant-form-vertical">
          {refUrl && (
            <CopyToClipboard text={refUrl}>
              <AntForm.Item label={t('app.referralBanner.link')}>
                <Input
                  type="text"
                  disabled
                  value={refUrl}
                  autoComplete={'off'}
                  placeholder={t('auth.registerForm.email_hint')}
                  suffix={
                    <i
                      className="key-icon key-icon--copy"
                      style={{ cursor: 'pointer' }}
                    />
                  }
                />
              </AntForm.Item>
            </CopyToClipboard>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
