import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ACTIVE_INSTANCES,
  Instance,
  InstanceState,
  SCHEDULED_FOR_DELETE,
} from './types';
import {
  createInstanceAction,
  deleteInstanceAction,
  getInstancesAction,
  linkInstanceAction,
  revertInstanceAction,
} from './actions';
import { PaginationResponse } from '../global/types';
import { createPaginatedState, toggleStateLoader } from '../../helpers';

const initialState: InstanceState = {
  creatingLoading: false,
  creatingInstance: null,
  isActiveLoading: false,
  isDeletingLoading: false,
  isLinkingLoading: false,
  activeInstances: createPaginatedState<Instance>(),
  deletingInstances: createPaginatedState<Instance>(),
};

export const slice = createSlice({
  name: 'instance',
  initialState,
  reducers: {
    toggleStateLoader,
    markAsCreated(state, { payload }: PayloadAction<Instance>) {
      const instance = state.activeInstances.items.find(
        ({ id }) => id === payload.id,
      );
      if (instance === undefined) {
        return;
      }
      instance.installed = true;
      instance.activateUrl = payload.activateUrl;
      instance.websiteId = payload.websiteId;
    },
  },
  extraReducers: {
    [getInstancesAction.success.toString()](
      state,
      {
        payload,
      }: PayloadAction<PaginationResponse<Instance> & { type: string }>,
    ) {
      const keysMap = {
        [ACTIVE_INSTANCES]: 'activeInstances',
        [SCHEDULED_FOR_DELETE]: 'deletingInstances',
      };
      state[keysMap[payload.type]] = payload;
    },
    [createInstanceAction.success.toString()](
      state,
      { payload }: PayloadAction<Instance>,
    ) {
      state.activeInstances.items.push(payload);
    },
    [linkInstanceAction.success.toString()](
      state,
      { payload }: PayloadAction<Instance>,
    ) {
      state.activeInstances.items.push(payload);
    },
    [deleteInstanceAction.success.toString()](
      state,
      { payload }: PayloadAction<Instance>,
    ) {
      state.deletingInstances.items.push(payload);
      state.activeInstances.items = state.activeInstances.items.filter(
        ({ id }) => id !== payload.id,
      );
    },
    [revertInstanceAction.success.toString()](
      state,
      { payload }: PayloadAction<Instance>,
    ) {
      state.activeInstances.items.push(payload);
      state.deletingInstances.items = state.deletingInstances.items.filter(
        ({ id }) => id !== payload.id,
      );
    },
  },
});

export const { actions } = slice;
