export const AUTH_REGISTER = '/auth/register';
export const AUTH_LOGIN = '/auth/login';
export const AUTH_FORGOT_PASSWORD = '/auth/forgot-password';
export const AUTH_RESET_PASSWORD = '/auth/reset-password/:token';
export const AUTH_LOGOUT = '/auth/logout';
export const AUTH_VERIFY_SESSION = '/auth/verify/:id';

export const INSTANCE_CREATE = '/instance';
export const INSTANCE_GET = '/instance/:id';
export const INSTANCE_DELETE = '/instance/:id';
export const INSTANCE_REVERT = '/instance/:id/revert';
export const INSTANCE_LIST = '/instance';
export const INSTANCE_LINK = '/instance/link/:code';

export const ACCOUNT_GET = '/account';
export const ACCOUNT_CHANGE_PASSWORD = '/account/password';
export const ACCOUNT_ACTIVATE_REFERRAL = '/account/referral';
export const ACCOUNT_GET_REFERRALS = '/account/referral';
export const ACCOUNT_GET_REFERRAL_ACCRUALS = '/account/referral/accrual';
export const ACCOUNT_GET_REFERRAL_PAYOUTS = '/account/referral/payout';
export const ACCOUNT_CREATE_REFERRAL_PAYOUTS = '/account/referral/payout';
export const ACCOUNT_GET_REFERRAL_STATS = '/account/referral/statistic';
