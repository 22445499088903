import React, { CSSProperties } from 'react';
import { Spin, Pagination } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { TFunction, useTranslation } from 'react-i18next';
import './styles.scss';

export interface TableColumn {
  title?: string;
  dataIndex: string;
  key: string;
  render?: (value) => React.ReactElement | string | number;
  formatValue?: (value) => string | number | null;
  tdStyle?: CSSProperties;
}

export interface Pagination {
  currentPage: number;
  itemsPerPage: number;
  total: number;
  onChange?: (page: number) => void;
}

interface Props<T> {
  columns: TableColumn[];
  data: T[];
  loading?: boolean;
  pagination?: Pagination | undefined;
}

const renderColumnData = (
  rowData: any,
  column: TableColumn,
): React.ReactElement | undefined | string => {
  const rowDataValue = rowData[column.dataIndex];
  return (
    <td style={column?.tdStyle || {}} key={column.dataIndex}>
      {(column.render ? column.render(rowData) : undefined) ||
        (column.formatValue
          ? column.formatValue(
              rowDataValue !== undefined ? rowDataValue : rowData,
            )
          : undefined) ||
        rowDataValue}
    </td>
  );
};

const renderTableBody = (data: any[], columns: TableColumn[], t: TFunction) => {
  if (data.length) {
    return (
      <tbody>
        {data.map(rowData => (
          <tr key={rowData.id}>
            {columns.map(column => renderColumnData(rowData, column))}
          </tr>
        ))}
      </tbody>
    );
  }

  return (
    <tbody>
      <tr>
        <td
          colSpan={columns.length}
          style={{ textAlign: 'center', padding: '30px 30px 20px' }}
        >
          <i className="key-icon key-icon--empty" />
          <div style={{ paddingTop: '10px' }}>
            <strong>{t('base.empty_list')}</strong>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export function Table({
  data,
  columns,
  pagination,
  loading = false,
}: Props<any>): React.ReactElement {
  const [t] = useTranslation();

  return (
    <div>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <table
          className={['account-table crm-list', loading && 'has-loading'].join(
            ' ',
          )}
        >
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.key}>{column.title}</th>
              ))}
            </tr>
          </thead>
          {renderTableBody(data, columns, t)}
        </table>
        {pagination && pagination.total > pagination.itemsPerPage && (
          <Pagination
            size="small"
            defaultPageSize={pagination.itemsPerPage}
            total={pagination.total}
            defaultCurrent={pagination.currentPage}
            onChange={pagination.onChange}
          />
        )}
      </Spin>
    </div>
  );
}
