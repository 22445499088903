import { combineReducers } from '@reduxjs/toolkit';
import { slice as authSlice } from './slices/auth';
import { slice as instanceSlice } from './slices/instance';
import { slice as accountSlice } from './slices/account';

export function createReducer() {
  return combineReducers({
    auth: authSlice.reducer,
    instance: instanceSlice.reducer,
    account: accountSlice.reducer,
  });
}
