import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as paths from 'routes/urls';

const navigation = [
  {
    key: 'profile',
    pathname: paths.APP_ACCOUNT_INDEX,
  },
  {
    key: 'referrals',
    pathname: paths.APP_ACCOUNT_REFERRALS,
  },
  {
    key: 'payments',
    pathname: paths.APP_ACCOUNT_PAYOUTS,
  },
  {
    key: 'stats',
    pathname: paths.APP_ACCOUNT_STATISTIC,
  },
  {
    key: 'rules',
    pathname: paths.APP_ACCOUNT_RULES,
  },
];

export function AccountNavigation(): React.ReactElement {
  const [t] = useTranslation();

  return (
    <div className="account__left">
      <ul>
        <li>
          {navigation.map(({ key, pathname }) => (
            <NavLink
              key={key}
              to={{
                pathname,
              }}
            >
              {t('app.account.navigation.' + key)}
            </NavLink>
          ))}
        </li>
      </ul>
    </div>
  );
}
