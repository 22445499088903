import React from 'react';
import { Router } from '../Router/Router';
import { Helmet } from 'react-helmet-async';

export function App(): React.ReactElement {
  return (
    <React.Fragment>
      <Helmet titleTemplate="%s - KeyCRM" defaultTitle="KeyCRM" />
      <Router />
    </React.Fragment>
  );
}
