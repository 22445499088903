import React from 'react';
import { Tag } from 'antd';
import Table from 'components/Table';
import { Account, ReferralAccrual } from 'store/slices/account/types';
import { Pagination, TableColumn } from 'components/Table/Table';
import { formatPhone, formatMoney } from 'helpers/account';
import { formatDate } from 'helpers/date';
import { useTranslation } from 'react-i18next';

interface RegistrationTableProps {
  referrals: Account[];
  loading: boolean;
  pagination: Pagination;
}

export function RegistrationsTable({
  referrals,
  loading,
  pagination,
}: RegistrationTableProps): React.ReactElement {
  const [t] = useTranslation();
  const columns: TableColumn[] = [
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: t('app.account.referrals.table_date'),
      formatValue: formatDate,
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: t('app.account.referrals.table_user'),
      render: (row: Account) => row.firstName + ' ' + (row.lastName || ''),
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
    },
    {
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      title: t('app.account.referrals.table_phone'),
      formatValue: formatPhone,
    },
    {
      key: 'instancesCount',
      dataIndex: 'instancesCount',
      title: t('app.account.referrals.table_count'),
      tdStyle: {
        textAlign: 'center',
      },
      render: (row: Account) => <span>{row.instancesCount}</span>,
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('app.account.referrals.table_status'),
      render: (value: Account) => (
        <Tag color={value.verifiedAt ? 'green' : 'red'}>
          {value.verifiedAt
            ? t('app.account.referrals.table_status_active')
            : t('app.account.referrals.table_status_pending')}
        </Tag>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      data={referrals}
      loading={loading}
      pagination={pagination}
    />
  );
}

interface ReferralAccrualsProps {
  referralAccruals: ReferralAccrual[];
  loading: boolean;
  pagination: Pagination;
}

export function ReferralAccrualsTable({
  referralAccruals,
  loading,
  pagination,
}: ReferralAccrualsProps): React.ReactElement {
  const [t] = useTranslation();
  const columns: TableColumn[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: t('app.account.referrals.table_user'),
      render: (row: ReferralAccrual) =>
        row.payer.firstName + ' ' + (row.payer.lastName || ''),
    },
    {
      key: 'instance',
      dataIndex: 'instance',
      title: t('app.account.referrals.table_crm_name'),
      render: (row: ReferralAccrual) => row.instanceName,
    },
    {
      key: 'paymentAmount',
      dataIndex: 'paymentAmount',
      title: t('app.account.referrals.table_sum'),
      formatValue: formatMoney,
    },
    {
      key: 'paymentProfit',
      dataIndex: 'paymentProfit',
      title: t('app.account.referrals.table_profit'),
      formatValue: formatMoney,
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: t('app.account.referrals.table_payment_date'),
      formatValue: formatDate,
    },
  ];
  return (
    <Table
      columns={columns}
      data={referralAccruals}
      loading={loading}
      pagination={pagination}
    />
  );
}
