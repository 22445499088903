import * as api from 'api/account';
import { put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions';
import { actions } from './index';
import {
  activateReferralAction,
  getReferralsAction,
  getReferralAccrualsAction,
  updatePasswordAction,
  getReferralPayoutsAction,
  createReferralPayoutAction,
} from './actions';

function* doUpdatePassword(
  action: ReturnType<typeof updatePasswordAction.request>,
) {
  try {
    yield put(actions.toggleStateLoader('isUpdatingPassword'));
    const { data } = yield api.updatePassword(action.payload);
    yield put(updatePasswordAction.success({}));
    resolvePromiseAction(action, data);
  } catch (e) {
    console.log(e);
    yield put(updatePasswordAction.failure(e));
    rejectPromiseAction(action, e);
  } finally {
    yield put(actions.toggleStateLoader('isUpdatingPassword'));
  }
}

function* doActivateReferral(
  action: ReturnType<typeof activateReferralAction.request>,
) {
  try {
    yield put(actions.toggleStateLoader('isActivatingReferral'));
    const { data } = yield api.activateReferral();
    yield put(activateReferralAction.success({ refNumber: data.refNumber }));
    resolvePromiseAction(action, data);
  } catch (e) {
    console.log(e);
    yield put(activateReferralAction.failure(e));
    rejectPromiseAction(action, e);
  } finally {
    yield put(actions.toggleStateLoader('isActivatingReferral'));
  }
}

function* doFetchReferrals(
  action: ReturnType<typeof getReferralsAction.request>,
) {
  try {
    yield put(actions.toggleStateLoader('isLoadingReferrals'));
    const { data } = yield api.getAccountReferrals(action.payload.page);
    yield put(getReferralsAction.success(data));
    resolvePromiseAction(action, data);
  } catch (e) {
    console.log(e);
    yield put(getReferralsAction.failure(e));
    rejectPromiseAction(action, e);
  } finally {
    yield put(actions.toggleStateLoader('isLoadingReferrals'));
  }
}

function* doFetchReferralAccruals(
  action: ReturnType<typeof getReferralAccrualsAction.request>,
) {
  try {
    yield put(actions.toggleStateLoader('isLoadingAccruals'));
    const { data } = yield api.getAccountReferralAccruals(action.payload.page);
    yield put(getReferralAccrualsAction.success(data));
    resolvePromiseAction(action, data);
  } catch (e) {
    console.log(e);
    yield put(getReferralAccrualsAction.failure(e));
    rejectPromiseAction(action, e);
  } finally {
    yield put(actions.toggleStateLoader('isLoadingAccruals'));
  }
}

function* doFetchReferralPayouts(
  action: ReturnType<typeof getReferralPayoutsAction.request>,
) {
  try {
    yield put(actions.toggleStateLoader('isLoadingPayouts'));
    const { data } = yield api.getAccountReferralPayouts();
    yield put(getReferralPayoutsAction.success(data));
    resolvePromiseAction(action, data);
  } catch (e) {
    console.log(e);
    yield put(getReferralPayoutsAction.failure(e));
    rejectPromiseAction(action, e);
  } finally {
    yield put(actions.toggleStateLoader('isLoadingPayouts'));
  }
}

function* doCreateReferralPayout(
  action: ReturnType<typeof createReferralPayoutAction.request>,
) {
  try {
    yield put(actions.toggleStateLoader('isCreatingPayout'));
    const { data } = yield api.createAccountReferralPayouts(action.payload);
    yield put(createReferralPayoutAction.success(data));
    resolvePromiseAction(action, data);
  } catch (e) {
    console.log(e);
    yield put(createReferralPayoutAction.failure(e));
    rejectPromiseAction(action, e);
  } finally {
    yield put(actions.toggleStateLoader('isCreatingPayout'));
  }
}

export function* accountSaga() {
  yield takeLatest(updatePasswordAction.request, doUpdatePassword);
  yield takeLatest(activateReferralAction.request, doActivateReferral);
  yield takeEvery(getReferralsAction.request, doFetchReferrals);
  yield takeEvery(getReferralAccrualsAction.request, doFetchReferralAccruals);
  yield takeEvery(getReferralPayoutsAction.request, doFetchReferralPayouts);
  yield takeEvery(createReferralPayoutAction.request, doCreateReferralPayout);
}
