import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import * as serviceWorker from './serviceWorker';
import { configureAppStore } from './store';
import { Provider as StoreProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import reportWebVitals from 'reportWebVitals';

import './i18n';

import './styles/index.scss';

import App from './components/App';

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const { NODE_ENV, REACT_APP_CAPTCHA_KEY, BUGSNAG_API_KEY } = process.env;

Bugsnag.start({
  apiKey: String(BUGSNAG_API_KEY),
  plugins: [new BugsnagPluginReact()],
});
// @ts-ignore
const ErrorBoundary: React.ComponentType = Bugsnag.getPlugin(
  'react',
)?.createErrorBoundary(React);

ReactDOM.render(
  <React.Fragment>
    <GoogleReCaptchaProvider
      reCaptchaKey={REACT_APP_CAPTCHA_KEY}
      scriptProps={{
        async: false,
        defer: false,
        nonce: undefined,
      }}
    >
      <StoreProvider store={store}>
        <HelmetProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </HelmetProvider>
      </StoreProvider>
    </GoogleReCaptchaProvider>
  </React.Fragment>,
  MOUNT_NODE,
);

// @ts-ignore
if (module.hot && NODE_ENV !== 'production') {
  // @ts-ignore
  module.hot.accept();
}

serviceWorker.unregister();

reportWebVitals(NODE_ENV !== 'production' ? console.log : undefined);
