import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { RootState } from 'types/RootState';
import * as paths from 'routes/urls';
import { VerificationInput } from 'components/Form/VerificationInput/VerificationInput';
import {
  validateTokenAction,
  verifySessionAction,
} from 'store/slices/auth/actions';
import { actions as authActions } from 'store/slices/auth';
import { actions as accountActions } from 'store/slices/account';
import AccountBox from 'components/Containers/AccountBox';
import { Button } from 'antd';
import Countdown from 'react-countdown';
import events from 'utils/events';
import './styles.scss';

const VERIFICATION_CODE_LENGTH = 6;

interface LocationState {
  state: {
    from: string;
    next: string;
  };
}

export function Verification(): React.ReactElement {
  const { sessionId, isSessionLoading } = useSelector(
    (state: RootState) => state.auth,
  );
  const [verificationCode, setVerificationCode] = React.useState<string>('');
  const [
    isVerificationFailed,
    setIsVerificationFailed,
  ] = React.useState<boolean>(false);
  const canVerify = verificationCode.length === VERIFICATION_CODE_LENGTH;
  const location: LocationState = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const RenderCountdown = () =>
    React.useMemo(() => {
      return <Countdown date={Date.now() + 300000} renderer={renderer} />;
    }, []);

  React.useEffect(() => {
    if (!sessionId) {
      history.push(paths.AUTH_REGISTER);
    }
    events.phoneNumberConfirmation();
  }, [sessionId, history]);

  const handleVerificationChange = (value: string): void => {
    setVerificationCode(value);
  };

  const verifyCode = (): void => {
    setIsVerificationFailed(false);
    dispatch(
      verifySessionAction.request({
        verificationCode,
        sessionId: sessionId as string,
      }),
    )
      .then(onVerifySuccess)
      .catch(onVerifyFailed);
  };

  const onVerifySuccess = () => {
    dispatch(validateTokenAction.request()).then(user => {
      if (location.state.from === paths.AUTH_REGISTER) {
        dispatch(authActions.setRegisteringUser(null));
      }
      dispatch(accountActions.setAccount(user));
      history.push(location.state.next);
    });
  };

  const onVerifyFailed = () => setIsVerificationFailed(true);

  const renderer = ({ completed, formatted: { minutes, seconds } }) => {
    if (completed) {
      return <span>{t('verification.countdown_finished')}</span>;
    } else {
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <AccountBox image="warehouse">
      <div className={'ant-form ant-form-vertical accountbox__form'}>
        <div className="accountbox__form-inner">
          <div className="verification-form">
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-label">
                <label className="" title="Your First and Last Name">
                  {t('verification.title')}
                </label>
              </div>
              <VerificationInput
                length={VERIFICATION_CODE_LENGTH}
                onChange={handleVerificationChange}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                fontSize: '24px',
                padding: '10px 0',
              }}
            >
              {RenderCountdown()}
            </div>
            <div
              className="verification-form__error-text"
              style={{
                display: isVerificationFailed ? 'block' : 'none',
              }}
            >
              {isVerificationFailed && <div> {t('verification.failed')} </div>}
            </div>
            <div className="verification-form__buttons">
              <Button
                block
                type="primary"
                htmlType="submit"
                onClick={verifyCode}
                disabled={!canVerify}
                loading={isSessionLoading}
              >
                {t('base.confirm')}
              </Button>
              <Button
                type="link"
                onClick={() => history.goBack()}
                style={{ marginTop: '10px' }}
              >
                <span>{t('base.back')}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </AccountBox>
  );
}
