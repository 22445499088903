import React from 'react';
import { Button, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory, useParams } from 'react-router';
import * as api from 'api/instances';
import Progress from './components/Progress';
import useInterval from '@use-it/interval';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/RootState';
import { AxiosError } from 'axios';
import { ERROR_404 } from '../../../routes/urls';
import events from '../../../utils/events';
import './styles.scss';

const { Panel } = Collapse;

export function CreatingProgress(): React.ReactElement {
  const { instanceId } = useParams<{ instanceId: string }>();
  const { account } = useSelector((state: RootState) => state.account);
  const history = useHistory();
  const [created, setIsCreated] = React.useState<boolean>(false);
  const [activateUrl, setActivateUrl] = React.useState<string | null>(null);
  const [shouldRepeat, setShouldRepeat] = React.useState<boolean>(false);
  const [animationFinished, setAnimationFinished] = React.useState<boolean>(
    false,
  );
  const [t] = useTranslation();

  const getInstance = () => {
    const onSuccess = ({ data: { installed, activateUrl: url } }) => {
      if (installed && !created) {
        setActivateUrl(url);
        return setIsCreated(true);
      }
      !shouldRepeat && setShouldRepeat(true);
    };

    const onFailure = ({ response }: AxiosError) => {
      if (response?.status === 404) {
        history.push(ERROR_404);
      }
    };

    !created && api.getInstance(instanceId).then(onSuccess).catch(onFailure);
  };

  const onAnimationFinish = () => {
    setTimeout(() => {
      setAnimationFinished(true);
    }, 1000);
  };

  useInterval(getInstance, created ? null : 2000);

  React.useEffect(() => {
    events.created();
  }, []);

  return (
    <React.Fragment>
      <Prompt
        when={created}
        message={() => 'Пожалуйста, дождитесь завершения установки CRM!'}
      />
      {!animationFinished ? (
        <Progress done={created} onFinish={onAnimationFinish} />
      ) : (
        <div className="animate__animated animate__fadeIn">
          <div className="logo" />
          <div className="accountbox wide auth_layout">
            <div className="accountbox__form">
              <div className="accountbox__form-inner">
                <br />
                <br />
                <br />
                <br />

                <h3 className="margin-bottom-halve">
                  {t('app.newInstance.register_thank')}
                </h3>
                <p style={{ fontSize: '16px' }}>
                  {t('app.newInstance.account_created')}:
                  <strong> {account?.email}</strong>
                </p>
                <p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('app.newInstance.text_spam'),
                    }}
                  />
                  {account?.email && account.email.indexOf('gmail') > 0 && (
                    <span>{t('app.newInstance.text_spam_gmail')}</span>
                  )}
                </p>
                <br />
                <br />

                <Button
                  size="large"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  className="form-button"
                >
                  <a href={String(activateUrl)} rel="noopener noreferrer">
                    {t('app.newInstance.go_to_crm')}
                  </a>
                </Button>

                <br />
                <br />
                <br />
                <br />

                <iframe
                  width="100%"
                  height="350"
                  src="https://www.youtube.com/embed/7rtMFAltspg"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <br />
                <br />
                <Button
                  size="large"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  className="form-button"
                >
                  <a
                    href="https://help.keycrm.app/getting-started-with-keycrm/piervyi-dien-s-key-crm"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t('app.newInstance.first_steps')}
                  </a>
                </Button>

                <h3 className="margin-top-four">{t('app.newInstance.faq')}</h3>

                <Collapse
                  accordion={true}
                  bordered={false}
                  defaultActiveKey={['1']}
                  className="text-left margin-bottom-four"
                >
                  <Panel header={t('app.newInstance.q1')} key="1">
                    {t('app.newInstance.a1')}
                  </Panel>
                  <Panel header={t('app.newInstance.q2')} key="2">
                    {t('app.newInstance.a2')}
                  </Panel>
                  <Panel header={t('app.newInstance.q3')} key="3">
                    {t('app.newInstance.a3')}
                  </Panel>
                  <Panel header={t('app.newInstance.q4')} key="4">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('app.newInstance.a4'),
                      }}
                    />
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
