import httpClient from './http.client';
import * as paths from './paths';
import { CreatingInstance } from '../store/slices/instance/types';
import { pathToRoute } from '../utils/path-to-route';

export const createInstance = (params: CreatingInstance) => {
  return httpClient.post(paths.INSTANCE_CREATE, params);
};

export const getInstance = (id: string) => {
  return httpClient.get(
    pathToRoute(paths.INSTANCE_GET, {
      id,
    }),
  );
};

export const getInstances = (page = 1, type: string = 'all') => {
  return httpClient.get(paths.INSTANCE_LIST, { params: { page, type } });
};

export const deleteInstance = (id: string) => {
  return httpClient.delete(
    pathToRoute(paths.INSTANCE_DELETE, {
      id,
    }),
  );
};

export const revertInstance = (id: string) => {
  return httpClient.post(
    pathToRoute(paths.INSTANCE_REVERT, {
      id,
    }),
  );
};

export const linkInstance = (code: string) => {
  return httpClient.post(
    pathToRoute(paths.INSTANCE_LINK, {
      code,
    }),
  );
};
