import React from 'react';
import { getAccountReferralStats } from 'api/account';
import { TableColumn } from 'components/Table/Table';
import Table from 'components/Table';
import { Button, DatePicker, Form } from 'antd';
import './styles.scss';
import dayjs from 'dayjs';
import moment from 'moment';
import { useLoading } from '../../../hooks';
import { useTranslation } from 'react-i18next';

export function Statistics(): React.ReactElement {
  const [t] = useTranslation();
  const [startDate, setStartDate] = React.useState<string>(
    String(moment(dayjs().day(0).format('YYYY-MM-DD')).format('YYYY-MM-DD')),
  );
  const [endDate, setEndDate] = React.useState<string>(
    String(moment(dayjs().day(6).format('YYYY-MM-DD')).format('YYYY-MM-DD')),
  );
  const { loading, toggleLoading } = useLoading(false);
  const [stats, setStats] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    toggleLoading();
    getAccountReferralStats(startDate, endDate)
      .then(({ data }) => {
        setStats(data);
      })
      .finally(toggleLoading);
  }, [startDate, endDate]);

  const tableColumns: TableColumn[] = [
    {
      dataIndex: 'date',
      key: 'date',
      title: t('app.account.stats.table_date'),
    },
    {
      dataIndex: 'visits',
      key: 'visits',
      title: t('app.account.stats.table_visits'),
    },
    {
      dataIndex: 'registrations',
      key: 'registrations',
      title: t('app.account.stats.table_registrations'),
    },
  ];

  const onPickerChange = (value, callback) => {
    callback(value?.format('YYYY-MM-DD'));
  };

  const doSearch = () => {
    toggleLoading();
    getAccountReferralStats(startDate, endDate)
      .then(({ data }) => {
        setStats(data);
      })
      .finally(toggleLoading);
  };

  return (
    <div className="box">
      <h3>{t('app.account.stats.title')}</h3>
      <div className="referral-stats">
        <div>
          <Form.Item label={t('app.account.stats.date_from')}>
            <DatePicker
              format="YYYY-MM-DD"
              style={{ minWidth: '250px' }}
              defaultValue={moment(dayjs().day(0).format('YYYY-MM-DD'))}
              onChange={value => onPickerChange(value, setStartDate)}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item label={t('app.account.stats.date_to')}>
            <DatePicker
              format="YYYY-MM-DD"
              style={{ minWidth: '250px' }}
              defaultValue={moment(dayjs().day(6).format('YYYY-MM-DD'))}
              onChange={value => onPickerChange(value, setEndDate)}
            />
          </Form.Item>
        </div>
        <Button
          type="primary"
          htmlType="button"
          className="form-button"
          onClick={doSearch}
        >
          {t('app.account.stats.button')}
        </Button>
      </div>
      <Table columns={tableColumns} data={stats} loading={loading} />
    </div>
  );
}
