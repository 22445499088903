import React from 'react';
import { LoginForm } from './components/LoginForm';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { validateTokenAction } from 'store/slices/auth/actions';
import { actions } from 'store/slices/account';
import { APP_ACCOUNT_INDEX } from 'routes/urls';

export function Login() {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogin = () => {
    dispatch(validateTokenAction.request()).then(user => {
      dispatch(actions.setAccount(user));
      history.push(APP_ACCOUNT_INDEX);
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('auth.loginForm.title')}</title>
      </Helmet>
      <LoginForm onLogin={handleLogin} />
    </React.Fragment>
  );
}
