import React, { useState } from 'react';
import { Button, Col, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CreateInstanceModal, LinkInstanceModal } from './components/modals';
import { RootState } from 'types/RootState';
import { mapPagination } from 'helpers/table';
import { useTranslation } from 'react-i18next';
import {
  deleteInstanceAction,
  getInstancesAction,
  revertInstanceAction,
} from 'store/slices/instance/actions';
import * as Table from './components/tables';
import {
  Instance,
  ACTIVE_INSTANCES,
  SCHEDULED_FOR_DELETE,
} from 'store/slices/instance/types';

export function Dashboard(): React.ReactElement {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const {
    activeInstances,
    deletingInstances,
    isActiveLoading,
    isDeletingLoading,
  } = useSelector((state: RootState) => state.instance);
  const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false);
  const [isLinkVisible, setIsLinkVisible] = useState<boolean>(false);

  const onCreateCanceled = () => setIsCreateVisible(false);
  const onInstanceCreated = ({ domain }: Instance) => {
    setIsCreateVisible(false);
    message.success('CRM успешно добалена. Пожалуйста дождитесь установки!');
  };
  const deleteInstance = (instance: Instance) => {
    dispatch(deleteInstanceAction.request(instance)).then(() => {
      message.success('CRM успешно добалена в список для удаления');
    });
  };
  const revertInstance = (instance: Instance) => {
    dispatch(revertInstanceAction.request(instance)).then(() => {
      message.success('Удаление успешно отменено');
    });
  };

  const closeLinkModal = () => setIsLinkVisible(false);

  const onActivePageChange = page => {
    dispatch(
      getInstancesAction.request({ page, type: ACTIVE_INSTANCES }),
    ).then();
  };
  React.useEffect(() => {
    dispatch(
      getInstancesAction.request({ page: 1, type: ACTIVE_INSTANCES }),
    ).then();
    dispatch(
      getInstancesAction.request({ page: 1, type: SCHEDULED_FOR_DELETE }),
    ).then();
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="box">
        <h3>{t('app.account.instances.title')}</h3>
        <Table.ActiveInstancesTable
          loading={isActiveLoading}
          instances={activeInstances.items}
          onDelete={deleteInstance}
          pagination={mapPagination(activeInstances, onActivePageChange)}
        />
        <br />

        <Row gutter={16}>
          <Col span={12}>
            <Button
              block
              type="primary"
              htmlType="button"
              onClick={() => setIsCreateVisible(true)}
            >
              <i className="key-icon key-icon--plus" />
              {t('app.account.create_new.button_text')}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              block
              type="default"
              htmlType="button"
              onClick={() => setIsLinkVisible(true)}
            >
              <i className="key-icon key-icon--plus" />
              {t('app.account.add_existing.title')}
            </Button>
          </Col>
        </Row>
      </div>
      {deletingInstances.items.length > 0 && (
        <div className="box">
          <h3>{t('app.account.deleting.title')}</h3>
          <Table.DeleteScheduledTable
            loading={isDeletingLoading}
            instances={deletingInstances.items}
            onRevert={revertInstance}
            pagination={mapPagination(deletingInstances)}
          />
        </div>
      )}
      <CreateInstanceModal
        isModalVisible={isCreateVisible}
        handleOk={onInstanceCreated}
        handleCancel={onCreateCanceled}
      />
      <LinkInstanceModal
        isModalVisible={isLinkVisible}
        handleOk={closeLinkModal}
        handleCancel={closeLinkModal}
      />
    </React.Fragment>
  );
}
