import React from 'react';
import './styles.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'types/RootState';
import { formatMoney } from 'helpers/account';
import { useTranslation } from 'react-i18next';

export function StatsBanner(): React.ReactElement {
  const [t] = useTranslation();
  const { account } = useSelector((state: RootState) => state.account);
  const remindedBalance = React.useMemo(() => {
    return (
      Number(account?.referralBalance) - Number(account?.referralPayoutAmount)
    );
  }, [account]);
  return (
    <div className="payouts-stats-banner">
      <div className="payouts-stats-banner__header full-width">
        {t('app.account.payouts.payout_stat')}
      </div>
      <div className="payouts-stats-banner__content full-width">
        <div className="payouts-stats-banner__content-item">
          <div>{t('app.account.payouts.payout_total')}</div>
          <div>{formatMoney(Number(account?.referralBalance))}</div>
        </div>
        <div className="payouts-stats-banner__content-item">
          <div>{t('app.account.payouts.payout_paid')}</div>
          <div>{formatMoney(Number(account?.referralPayoutAmount))}</div>
        </div>
        <div className="payouts-stats-banner__content-item">
          <div>{t('app.account.payouts.payout_left')}</div>
          <div>{formatMoney(remindedBalance)}</div>
        </div>
      </div>
    </div>
  );
}
