const reservedWords = [
  'about',
  'abuse',
  'account',
  'account_db',
  'admanager',
  'admin',
  'admindashboard',
  'administrator',
  'adsense',
  'adwords',
  'affiliate',
  'affiliates',
  'amazon',
  'api',
  'assets',
  'auth',
  'beta',
  'billing',
  'blog',
  'buy',
  'cache',
  'calendar',
  'cdn',
  'client',
  'clients',
  'cname',
  'code',
  'community',
  'cpanel',
  'css',
  'dashboard',
  'dbs1',
  'dbs2',
  'dbs3',
  'dbs4',
  'dbs5',
  'demo',
  'dev',
  'developer',
  'developers',
  'development',
  'docs',
  'donate',
  'ebay',
  'elasticsearch',
  'email',
  'etsy',
  'faq',
  'feeds',
  'files',
  'forum',
  'ftp',
  'gateway',
  'gettingstarted',
  'git',
  'graph',
  'graphs',
  'help',
  'home',
  'http',
  'https',
  'images',
  'imap',
  'img',
  'integrations',
  'invoice',
  'invoices',
  'ios',
  'ipad',
  'iphone',
  'js',
  'keycrm',
  'knowledgebase',
  'log',
  'login',
  'logs',
  'mail',
  'manage',
  'message',
  'messages',
  'messaging',
  'mongodb',
  'my',
  'mysql',
  'network',
  'networks',
  'new',
  'news',
  'newsite',
  'nginx',
  'olx',
  'pages',
  'partner',
  'partnerpage',
  'partners',
  'payment',
  'payments',
  'phpmyadmin',
  'pop',
  'pop3',
  'popular',
  'press',
  'prom',
  'public',
  'random',
  'redirect',
  'register',
  'registration',
  'resolver',
  'rozetka',
  'rss',
  'sandbox',
  'search',
  'secure',
  'send',
  'server',
  'servers',
  'service',
  'shipping',
  'shippings',
  'shop',
  'shops',
  'signin',
  'signup',
  'sitemap',
  'sitenews',
  'sites',
  'sms',
  'smtp',
  'sorry',
  'ssl',
  'stage',
  'staging',
  'static',
  'statistics',
  'stats',
  'status',
  'store',
  'stores',
  'support',
  'survey',
  'surveys',
  'svn',
  'system',
  'systems',
  'talk',
  'templates',
  'tenant',
  'test',
  'tests',
  'trac',
  'track',
  'track-delivery',
  'tracking-code',
  'traefik',
  'translate',
  'upload',
  'uploads',
  'validation',
  'validations',
  'video',
  'videos',
  'webdisk',
  'webmail',
  'webmaster',
  'website',
  'websites',
  'whm',
  'whois',
  'wiki',
  'www',
];

export const isReservedWord = (word: string) => reservedWords.includes(word);
