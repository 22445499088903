import { Pagination } from '../components/Table/Table';
import { PaginatedState } from '../store/slices/global/types';

export const mapPagination = <T>(
  state: PaginatedState<T>,
  onChange?: (page: number) => void,
): Pagination => ({
  total: state.total,
  currentPage: state.currentPage,
  itemsPerPage: state.itemsPerPage,
  onChange,
});
