import Lottie from 'react-lottie-segments';
import loadingScene from './loading.scene.json';
import React from 'react';
import { LoaderProps } from '../types';

export const RepeatableLoader = (props: LoaderProps): JSX.Element => {
  return (
    <Lottie
      eventListeners={[
        {
          eventName: 'complete',
          callback: props.onAnimationComplete,
        },
      ]}
      options={{
        loop: true,
        autoplay: true,
        animationData: loadingScene,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
    />
  );
};
