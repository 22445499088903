import React from 'react';
import { StatsBanner } from './components/StatsBanner';
import { PayoutsTable } from './components/PayoutsTable';
import { Button, Col, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/RootState';
import { RequestPayoutModal } from './components/RequestPayoutModal';
import { mapPagination } from 'helpers/table';
import { getReferralPayoutsAction } from 'store/slices/account/actions';
import { useTranslation } from 'react-i18next';

export function Payouts(): React.ReactElement {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { account, isLoadingPayouts, referralPayouts } = useSelector(
    (state: RootState) => state.account,
  );
  const pendingRequests = Number(referralPayouts.meta?.pendingRequests);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

  const onPayoutCreated = () => {
    message.success(t('app.account.payouts.payout_request.messages.created'));
    setIsModalVisible(false);
  };

  React.useEffect(() => {
    dispatch(getReferralPayoutsAction.request({ page: 1 })).then(() => {});
  }, [dispatch]);

  return (
    <div>
      <StatsBanner />
      <div className="box">
        <h3>{t('app.account.payouts.title')}</h3>
        <PayoutsTable
          loading={isLoadingPayouts}
          payouts={referralPayouts.items}
          pagination={mapPagination(referralPayouts, page =>
            dispatch(getReferralPayoutsAction.request({ page })).then(() => {}),
          )}
        />
        <Row gutter={16} style={{ marginTop: '15px' }}>
          <Col span={12} offset={6}>
            <Button
              block
              type="primary"
              htmlType="button"
              onClick={() => setIsModalVisible(true)}
              disabled={
                Number(account?.referralBalance) < 50 || pendingRequests > 0
              }
            >
              {t('app.account.payouts.payout_request')}
            </Button>
          </Col>
        </Row>
      </div>
      <RequestPayoutModal
        isModalVisible={isModalVisible}
        handleOk={onPayoutCreated}
        handleCancel={() => setIsModalVisible(false)}
      />
    </div>
  );
}
