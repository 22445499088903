import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { promiseMiddleware } from 'redux-saga-promise-actions';
import createSagaMiddleware from 'redux-saga';
import { createReducer } from './reducers';
import rootSaga from './sagas';

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  const middlewares = [promiseMiddleware, sagaMiddleware];

  const store = configureStore({
    reducer: createReducer(),
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      ...middlewares,
    ],
    devTools:
      process.env.NODE_ENV !== 'production' ||
      process.env.PUBLIC_URL.length > 0,
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
