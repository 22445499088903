import React from 'react';
import { Form as AntForm, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface Props {
  isValid: boolean;
  onChange: (value: string) => void;
}

export function LinkInstanceForm({
  isValid,
  onChange,
}: Props): React.ReactElement {
  const [t] = useTranslation();

  return (
    <form className="link-instance-form ant-form-vertical">
      <div>{t('app.account.add_existing.subtitle')}:</div>
      <div className="list">
        <div>
          <span>1</span> {t('app.account.add_existing.rule_1')}
        </div>
        <div>
          <span>2</span> {t('app.account.add_existing.rule_2')}
        </div>
        <div>
          <span>3</span> {t('app.account.add_existing.rule_3')}
        </div>
      </div>
      <AntForm.Item
        label={t('app.account.add_existing.code')}
        help={!isValid ? t('app.account.add_existing.enter_code') : ''}
        validateStatus={!isValid ? 'error' : 'success'}
      >
        <Input
          style={{
            width: '300px',
          }}
          allowClear={true}
          autoComplete={'off'}
          onChange={({ target: { value } }) => onChange(value)}
        />
      </AntForm.Item>
    </form>
  );
}
