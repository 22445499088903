export default {
  base: {
    continue: 'Далі',
    back: 'Назад',
    confirm: 'Підтвердити',
    important: 'Увага!',
    cancel: 'Відмінити',
    add: 'Додати',
    yes: 'Так',
    empty_list: 'Список пустий',
    save: 'Зберегти',
    back_to_main: 'Повернутись на головну',
  },
  auth: {
    registerForm: {
      email: 'E-mail',
      email_hint: 'Приклад: yourmail@gmai.com',
      full_name: 'Ваше ім\'я та прізвище',
      full_name_hint: 'Приклад: Андрій Сидорчук',
      phone: 'Номер телефону',
      title: 'Підключіться сьогодні! Повний функціонал без оплат до 30 днів',
      already_exist: 'В мене вже є акаунт',
      why_email: 'На email будуть відправлені дані для входу',
      why_phone: 'На телефон відправимо SMS з кодом',
    },
    loginForm: {
      title: 'Вхід в акаунт KeyCRM',
      domain_name: 'Домен компанії',
      domain_hint: 'Наприклад: minitoys, megatrading, mebli-torg',
      last_visited_tenants: 'Раніше відвідані CRM:',
      password: 'Пароль',
      login: 'Вхід',
      forgotPassword: 'Забули пароль?',
      rememberMe: 'Запам\'ятати мене',
      noAccount: 'Немає акаунту?',
      register: 'Зареєструватись',
      login_failed: 'Невірний E-mail або пароль'
    },
    forgotPassword: {
      title: 'Відновлення паролю',
      subTitle: 'Введіть вашу E-mail адресу для відновлення паролю',
      toLogin: 'Назад',
      reset: 'Скинути',
      resetFailed: 'Користувач з таким E-mail не існує',
      toManyRequests: 'Забагато невдалих спроб. Будь ласка, спробуйте пізніше'
    },
    resetPassword: {
      title: 'Скидання паролю',
      password: 'Новий пароль',
      passwordConfirmation: 'Підтвердження нового паролю',
      save: 'Зберегти',
      passwordConfirmationFailed: 'Паролі не співпадають',
      success: 'Пароль успішно оновлено! Будь ласка, виконайте вхід'
    }
  },
  app: {
    newInstance: {
      title: 'Створюємо акаунт',
      account_name_hint: 'Якою буде адреса для входу в KeyCRM<br /> для вашої компанії?',
      account_name_example: 'Наприклад: minitoys, megatrading, mebli-torg',
      account_name_requirements: 'Тільки латинські букви (від 4 до 20), цифри та дефіс',
      account_name_taken: 'Цей домен вже зайнятий',
      confirmation: '<ul style="padding-left: 20px"><li>Ви обрали <strong>{{domain}}</strong> як назву домену.</li> <li>Обрану назву неможливо буде змінити в майбутньому.</li><li>Ви та ваші співробітники будуть входити в KeyCRM по посиланню:</li></ul><strong>https://{{domain}}.keycrm.app</strong><br /><br />Ви впевнені, що хочете використовувати саме таку назву?',
      register_thank: 'Дякуємо, що зареєструвались!',
      account_created: 'Дані для входу в KeyCRM відправлені на ваш Email',
      account_creation_in_progress: 'Роботи забігали та працюють, створюючи для вас особистий кабінет KeyCRM',
      account_enter_now: 'Увійти в акаунт зараз',
      account_tariff: 'Я ознайомлений(а) з <a href="https://keycrm.app/pricing" target="_blank">тарифами</a>',
      account_tos: 'Реєструючись, ви погоджуєтесь з <a href="https://keycrm.app/terms-of-use" target="_blank">Умовами використання</a> та <a href="https://keycrm.app/privacy-policy" target="_blank">Політикою конфіденційності</a>',
      text_spam: 'Якщо E-mail не прийшов протягом 5 хвилин - <strong>обов\'язково</strong> перевірте папку СПАМ',
      text_spam_gmail: '(а також вкладки Реклама, Промоакції та інші в Gmail)',
      or: 'Або',
      go_to_profile: 'Перейти до профілю',
      go_to_crm: 'Перейти в CRM',
      first_steps: 'Покрокове налаштування KeyCRM',
      faq: 'Часто задавані питання',
      q1: 'Чи потрібно прив’язувати картку до акаунту? Чи є автосписання?',
      a1: 'Ні, не потрібно. Ми взагалі не вимагаємо прив’язки і не зберігаємо дані Ваших карток.',
      q2: 'Коли я повинен буду заплатити?',
      a2: 'Оплата списується з вашого внутрішнього балансу в KeyCRM за фактом використання, кожного 1-го числа місяця. Оплату Ви можете зробити в будь-який зручний для Вас час. Також можна зробити оплату завчасно, щоб система не заблокувалася при негативному балансі.',
      q3: 'Як я можу заходити в KeyCRM?',
      a3: 'Для входу в KeyCRM Вам потрібен інтернет і будь-який браузер (Chrome, Opera, Firefox, Microsoft Edge) - на комп’ютері або на телефоні. Також є окремий мобільний додаток.',
      q4: 'Чи будуть мої дані в безпеці?',
      a4: 'Що стосується збереження даних - вони зберігаються в надійному європейському дата-центрі. Також у нас налаштовано регулярне резервне копіювання даних.<br /> Щодо нерозголошення Вашої інформації - згідно <a href="https://ua.keycrm.app/terms-of-use" target="_blank" rel="noopener noreferrer" >Користувацької угоди</a> ми не маємо права розголошувати інформацію наших клієнтів.',
    },
    account: {
      navigation: {
        logout: 'Вийти',
        profile: 'Мій профіль',
        referrals: 'Реферали',
        payments: 'Виплати',
        stats: 'Статистика',
        rules: 'Правила',
      },
      instances: {
        title: 'Доступні CRM',
        name: 'Назва',
        role: 'Роль',
        balance: 'Баланс',
        remove: 'Видалити',
        enter: 'Вхід'
      },
      add_existing: {
        title: 'Додати існуючу CRM',
        subtitle: 'Щоб додати існуючу CRM-систему в цей акаунт необхідно',
        rule_1: 'Виконати вхід в CRM, яку хочете додати в свій акаунт',
        rule_2: 'В цій CRM перейти в Мій профіль → Налаштування',
        rule_3: 'Скопіювати код вашої CRM та вставити його в поле нижче',
        code: 'Код CRM-системи',
        enter_code: 'Введіть код',
        msg_success: 'CRM успішно додана у ваш акаунт',
        msg_exists: 'CRM уже була додана у ваш акаунт раніше',
        msg_error: 'Помилка додавання CRM. Будь ласка, спробуйте ще раз',
      },
      create_new: {
        button_text: 'Створити нову CRM',
        title: 'Нова CRM',
        subtitle: 'Створіть адресу входу в KeyCRM для вашої компанії',
      },
      deleting: {
        title: 'CRM, заплановані до видалення',
        confirmation: 'Будуть видалені всі дані по замовленням, заявкам, покупцям та інші дані з CRM <strong>{{domain}}</strong>. Можливість входу буде заблокована. Ви дійсно хочете видалити цю CRM?',
        input_message: 'Для підтвердження введіть: <strong>{{domain}}</strong>'
      },
      settings: {
        title: 'Дані акаунту',
        title_password: 'Пароль від акаунта',
        full_name: 'Ім\'я та Прізвище',
        oldPassword: 'Поточний пароль',
        password: 'Новий пароль',
        passwordConfirmation: 'Підтвердження паролю',
        passwordConfirmationFailed: 'Паролі не співпадають'
      },
      referrals: {
        title: 'Реферали',
        tab_registrations: 'Реєстрації',
        tab_payments: 'Оплати',
        table_date: 'Дата реєстрації',
        table_user: 'Користувач',
        table_phone: 'Телефон',
        table_count: 'Кіл-сть CRM',
        table_crm_name: 'Назва CRM',
        table_sum: 'Сума оплати',
        table_profit: 'Прибуток',
        table_payment_date: 'Дата оплати',
        table_status: 'Статус',
        table_status_active: 'Активний',
        table_status_pending: 'Очікування',
      },
      payouts: {
        title: 'Історія виплат',
        payout_stat: 'Статистика виплат',
        payout_total: 'Всього нараховано',
        payout_paid: 'Виплачено',
        payout_left: 'Залишок на рахунку',
        payout_request: 'Запит на виведення коштів',
        payout_request_hint: 'Вкажіть реквізити для виплати (карта або рахунок ФОП)',
        table_date: 'Дата створення',
        table_amount: 'Сума',
        table_comment: 'Коментар',
        table_status: 'Статус',
        table_payout_date: 'Дата виплати',
        messages: {
          created: 'Запит на виведення коштів успішно створено. Будь-ласка очікуйте відповіді від менеджера'
        },
      },
      stats: {
        title: 'Статистика реферального посилання',
        button: 'Показати',
        date_from: 'Дата від',
        date_to: 'Дата до',
        table_date: 'Дата',
        table_visits: 'Переходи',
        table_registrations: 'Реєстрації',
      },
    },
    referralBanner: {
      promo: 'Запрошуйте нових користувачів та отримуйте до 50% від їх оплат протягом 12 місяців',
      read_more: 'Дізнатись більше',
      link: 'Ваше реферальне посилання',
    }
  },
  verification: {
    title: 'Введіть код підтвердження з SMS повідомлення',
    failed: 'Код підтвердження введено невірно',
    countdown_finished: 'Будь ласка, зверніться в чат підтримки',
  },
  validation: {
    mixed: {
      required: '${path} - обов\'язкове поле',
    },
    string: {
      email: '${path} адрес введено невірно', // don't provide a long messages!!!
      length: '${path} повинен складатись з ${length} цифр',
      min: '${path} повинен бути не менше ${min} символів'
    },
  },
  validation_rules: {
    unique_email: 'E-mail адреса вже використовується',
    unique_phoneNumber: 'Номер телефону вже використовується',
  },
  countries: {
    ukraine: 'Україна',
    // russia: 'Россия',
    // belarus: 'Білорусь',
    usa: 'США',
    poland: 'Польща',
    germany: 'Німеччина',
    lithuania: 'Литва',
    latvia: 'Латвія',
    estonia: 'Естонія',
    kazakhstan: 'Казахстан',
    kyrgyzstan: 'Киргизія',
    uzbekistan: 'Узбекистан',
    portugal: 'Португалія',
    turkey: 'Турція',
    spain: 'Іспанія',
    france: 'Франція',
    britain: 'Великобританія',
    canada: 'Канада',
    australia: 'Австралія',
    moldova: 'Молдова',
    romania: 'Румунія',
    italy: 'Італія',
    azerbaijan: 'Азербайджан',
    georgia: 'Грузія',
    armenia: 'Вірменія',
    slovakia: 'Словаччина',
    belgium: 'Бельгія',
    cyprus: 'Кіпр',
  },
  errors: {
    500: 'Сталася помилка, будь ласка, спробуйте пізніше',
    429: 'Занадто багато запитів, будь ласка, спробуйте пізніше',
    session_expired: 'Час сесії минув, будь ласка, авторизуйтесь знову',
  }
};
