import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form as AntForm, Input, InputNumber, message, Modal } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { RootState } from 'types/RootState';
import * as validation from 'helpers/validation';
import { createReferralPayoutAction } from '../../../../store/slices/account/actions';
import { setResponseValidation } from 'helpers/validation';

interface Props {
  isModalVisible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export function RequestPayoutModal({
  isModalVisible,
  handleCancel,
  handleOk,
}: Props): React.ReactElement {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { account, isCreatingPayout } = useSelector(
    (state: RootState) => state.account,
  );

  const rules = yup.object().shape({
    amount: yup
      .string()
      .required()
      .min(2)
      .label(t('app.account.payouts.table_amount')),
    comment: yup
      .string()
      .required()
      .min(6)
      .label(t('app.account.payouts.table_comment')),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(rules),
    defaultValues: {
      amount: '$ 50',
      comment: '',
    },
  });

  const handleConfirm = () => {
    handleSubmit(onFormSubmit);
  };

  const handleClose = () => {
    reset();
  };

  const onFormSubmit = value => {
    dispatch(
      createReferralPayoutAction.request({
        ...value,
        amount: Number(value.amount.replace(/\$/g, '').trimStart()),
      }),
    )
      .then(() => {
        handleOk();
        reset();
      })
      .catch(e => {
        if (e.response.status === 400) {
          message.error(e.response.data.message);
        } else if (e.response.status === 422) {
          setResponseValidation(setError, e.response.data.errors);
        }
      });
  };

  return (
    <Modal
      title={t('app.account.payouts.payout_request')}
      visible={isModalVisible}
      onOk={handleConfirm}
      onCancel={handleCancel}
      afterClose={handleClose}
      okText={t('base.add')}
      cancelText={t('base.cancel')}
      okButtonProps={{
        disabled: isCreatingPayout,
        loading: isCreatingPayout,
        htmlType: 'submit',
        form: 'payout',
      }}
    >
      <form id="payout" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="ant-form ant-form-vertical">
          <AntForm.Item
            label={t('app.account.payouts.table_amount')}
            help={validation.validationError(errors.amount)}
            validateStatus={validation.validationStatus(errors.amount)}
          >
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  id="amount"
                  min={'50'}
                  className="full-width"
                  max={String(account?.referralBalance?.toFixed(2))}
                  formatter={value =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => String(value).replace(/\$\s?|(,*)/g, '')}
                />
              )}
            />
          </AntForm.Item>
          <AntForm.Item
            label={t('app.account.payouts.table_comment')}
            help={validation.validationError(errors.comment)}
            validateStatus={validation.validationStatus(errors.comment)}
          >
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <Input.TextArea
                  {...field}
                  rows={4}
                  id="comment"
                  className="full-width"
                  placeholder={t('app.account.payouts.payout_request_hint')}
                />
              )}
            />
          </AntForm.Item>
        </div>
      </form>
    </Modal>
  );
}
