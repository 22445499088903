import { PaginatedState, PaginationRequest } from '../global/types';

export const ACTIVE_INSTANCES = 'active';

export const SCHEDULED_FOR_DELETE = 'scheduled_for_delete';

export interface InstanceState {
  creatingLoading: boolean;
  creatingInstance: CreatingInstance | null;
  isActiveLoading: boolean;
  isDeletingLoading: boolean;
  isLinkingLoading: boolean;
  activeInstances: PaginatedState<Instance>;
  deletingInstances: PaginatedState<Instance>;
}

export interface FetchInstances extends PaginationRequest {
  type: string;
}

export interface CreatingInstance {
  domain: string;
}

export interface Instance {
  id: string;
  domain: string;
  websiteId?: string | null;
  balance?: number | null;
  installed?: boolean | null;
  isFree?: boolean | null;
  activateUrl?: string | null;
  createdAt: string;
  scheduledDeleteAt: string | null;
  accountInstances?: AccountInstance[];
}

export interface AccountInstance {
  account: Account;
  role: object | null;
  referenceId: number | null;
  isOwner?: boolean | null;
}
