import React from 'react';
import { Loaders } from 'components/Loaders';
import { useTranslation } from 'react-i18next';

interface Props {
  done: boolean;
  onFinish: () => void;
}

export const Progress = ({ done, onFinish }: Props) => {
  const [t] = useTranslation();

  return (
    <div className="accountbox wide auth_layout">
      <div className="accountbox__form">
        <div className="accountbox__form-inner">
          <div style={{ width: '60px', margin: '0 auto' }}>
            {done ? (
              <Loaders.Success onAnimationComplete={onFinish} />
            ) : (
              <Loaders.Repeatable />
            )}
          </div>
          <h3>{t('app.newInstance.account_creation_in_progress')}</h3>
        </div>
      </div>
    </div>
  );
};
