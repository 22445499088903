import React from 'react';
import { Button, Col, Collapse, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { activateReferralAction } from 'store/slices/account/actions';
import { RootState } from '../../../types/RootState';

const { Panel } = Collapse;
export function Rules(): React.ReactElement {
  const dispatch = useDispatch();
  const { account, isActivatingReferral: loading } = useSelector(
    (state: RootState) => state.account,
  );
  const handleRefererOk = () => {
    dispatch(activateReferralAction.request()).then(() => {
      message.success('Реферальная программа успешно активирована');
    });
  };
  return (
    <div className="box">
      <Collapse defaultActiveKey={['1']} ghost accordion={true}>
        <Panel
          key="1"
          header="Что я могу делать в рамках реферальной программы?"
        >
          <p>
            Для того чтобы зарабатывать на реферальной программе, вы можете
            рассказывать о KeyCRM вашей аудитории любыми способами, которые не
            противоречат нашим правилам. Какие могут быть варианты:
            <br />
            <br />
            - Контент-маркетинг: публикация статей (кейсов, обзоров, сравнений,
            инструкций и т.д.) с использованием платформы в вашем блоге или на
            других тематических ресурсах;
            <br />
            <br />
            - Видео-обзоры: полноценные обзоры или упоминание KeyCRM в
            видео-контенте;
            <br />
            <br />
            - SMM: упоминание KeyCRM в постах в социальных сетях;
            <br />
            <br />
            - Email-маркетинг: использование промо-материалов в рассылках.
            <br />
            <br />- Прямые рекомендации: передача промокодов и реферальных
            ссылок вашим прямым клиентам и знакомым.
          </p>
        </Panel>
        <Panel
          header="Что считается нарушением условий реферального партнерства?"
          key="2"
        >
          <p>
            Есть несколько важных правил, при нарушении которых мы не начисляем
            комиссию.
            <span style={{ marginRight: '10px' }}>
              <br />
              <br />
              - Нельзя оплачивать свою подписку в сервисе по собственной
              реферальной ссылке ни в каких вариантах и комбинациях.
              <br />
              <br />
              - Запрещено размещать любые виды платной рекламы с использованием
              реферальной ссылки по брендовым запросам KeyCRM, KeyCRM.app.
              <br />
              <br />
              - Запрещено использовать спамные методы: рассылки, публикации на
              форумах и сообществах и другие массовые способы промо.
              <br />
              <br />
              - Нельзя размещать реферальную ссылку на сайтах-купонниках,
              агрегаторах скидок и других порталах, которые специализируются
              исключительно на скидочных предложениях.
              <br />
              <br />- Запрещено давать любую неправдивую информацию о сервисе, а
              также такую, которая вредит имиджу компании KeyCRM.
              <br />
              <br />
            </span>
            Ознакомьтесь с полным перечнем правил в партнерском соглашении. В
            случае нарушения комиссия будет заблокирована автоматически, при
            повторных нарушениях будем вынуждены исключить вас из реферальной
            программы.
          </p>
        </Panel>
      </Collapse>
      <div>
        <Row gutter={16} style={{ marginTop: '35px' }}>
          <Col span={12} offset={6}>
            <Button
              block
              type="primary"
              loading={loading}
              htmlType="button"
              onClick={handleRefererOk}
              disabled={account?.refNumber !== null}
            >
              Активировать реферальную программу
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
