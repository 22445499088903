import React, { ChangeEvent } from 'react';
import { Input } from 'antd';

interface Props {
  value: string;
  onChange: (string) => void;
  disabled?: boolean;
}

export function DomainInput({
  onChange,
  value,
  disabled,
}: Props): React.ReactElement {
  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    let newValue = value.toLowerCase();
    if (newValue[0] === '-') {
      newValue = newValue.substring(1);
    }
    onChange(newValue);
  };
  return (
    <Input
      id="domain_name"
      value={value}
      maxLength={20}
      disabled={disabled}
      onChange={handleChange}
      prefix="https://"
      suffix={process.env.REACT_APP_TENANT_HOST}
      className="domain"
    />
  );
}
