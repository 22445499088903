import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button, Form as AntForm, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import * as validation from 'helpers/validation';
import { RootState } from 'types/RootState';
import { actions } from 'store/slices/auth';
import { registerAction } from 'store/slices/auth/actions';
import { rules } from './rules';
import PhoneNumberInput from 'components/Form/PhoneNumberInput/PhoneNumberInput';
import { FormTitle } from 'components/Form/FormTitle/FormTitle';
import AccountBox from 'components/Containers/AccountBox';
import { AxiosResponse } from 'axios';
import { Captcha } from 'components/Form/Captcha/Captcha';
import events from 'utils/events';
import './styles.scss';

interface Props {
  onSubmit(data: any): void;
  onLogin(): void;
}

export function RegisterForm({ onSubmit, onLogin }: Props): React.ReactElement {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [captchaKey, setCaptchaKey] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string | null>(null);
  const [captchaVisible, setIsCaptchaVisible] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { selectedPhoneCode, registeringUser } = useSelector(
    (state: RootState) => state.auth,
  );
  const validationRules = rules(t, selectedPhoneCode);

  const defaultValues = React.useMemo(() => {
    return (
      registeringUser || {
        email: '',
        fullName: '',
        phoneNumber: '',
      }
    );
  }, [registeringUser]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(validationRules),
    defaultValues,
  });

  const onPhoneCodeChanged = (value: string) =>
    dispatch(actions.setSelectedCode(value.trimEnd()));

  const doRegister = (value, captcha: string | undefined = undefined) => {
    setLoading(true);
    events.identity(value);
    return dispatch(
      registerAction.request({
        ...value,
        captcha: captcha || captchaKey,
      }),
    ).then(onSubmit);
  };

  const onFormSubmit = value => {
    doRegister(value).catch(error => {
      if (error.response.status === 400) {
        handleResponseError(error.response);
      } else if (error.response.status === 422) {
        return handleCaptchaError(error.response, value);
      }
      setLoading(false);
    });
  };

  const handleResponseError = ({
    data: { field, rule, errorCode },
  }: AxiosResponse) => {
    if (errorCode !== 2000) {
      setError(field, {
        message: t(`validation_rules.${rule}_${field}`),
      });
    }
  };
  const handleCaptchaError = (
    { data: { errors } }: AxiosResponse,
    formValue,
  ) => {
    const hasCaptcha = errors.some(
      error => Object.keys(error)[0] === 'captcha',
    );
    if (!hasCaptcha) {
      return;
    }
    setTimeout(() => {
      // @ts-ignore
      executeRecaptcha().then(captcha => {
        doRegister(formValue, captcha)
          .catch(error => {
            if (error.response.status === 400) {
              handleResponseError(error.response);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }, 2000);
  };

  React.useEffect(() => {
    setTimeout(() => setIsCaptchaVisible(true), 2000);
    events.check();
  }, []);

  return (
    <AccountBox image="laptop">
      <form
        className={'ant-form ant-form-vertical accountbox__form'}
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <div className="accountbox__form-inner">
          <FormTitle>{t('auth.registerForm.title')}</FormTitle>
          <AntForm.Item
            label={t('auth.registerForm.full_name')}
            validateStatus={validation.validationStatus(errors.fullName)}
            help={validation.validationError(errors.fullName)}
          >
            <Controller
              name="fullName"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="full_name"
                  allowClear={true}
                  autoComplete={'off'}
                  disabled={loading}
                  placeholder={t('auth.registerForm.full_name_hint')}
                />
              )}
            />
          </AntForm.Item>

          <AntForm.Item
            label={t('auth.registerForm.email')}
            validateStatus={validation.validationStatus(errors.email)}
            help={
              validation.validationError(errors.email) ||
              (email && t('auth.registerForm.why_email'))
            }
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="email"
                  type="email"
                  allowClear={true}
                  onChange={e => {
                    field.onChange(e);
                    setEmail(e.target.value);
                  }}
                  autoComplete={'off'}
                  disabled={loading}
                  placeholder={t('auth.registerForm.email_hint')}
                />
              )}
            />
          </AntForm.Item>

          <AntForm.Item
            label={t('auth.registerForm.phone')}
            validateStatus={validation.validationStatus(errors.phoneNumber)}
            className="phone"
            help={
              validation.validationError(errors.phoneNumber) ||
              t('auth.registerForm.why_phone')
            }
          >
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <PhoneNumberInput
                  {...field}
                  ref={null}
                  disabled={loading}
                  defaultCode={selectedPhoneCode}
                  onCodeChanged={onPhoneCodeChanged}
                />
              )}
            />
          </AntForm.Item>

          <Button
            block
            type="primary"
            htmlType="submit"
            loading={loading}
            className="form-button"
          >
            {t('base.continue')}
          </Button>
          <div className="margin-top-one margin-bottom-one">
            <Button type="link" htmlType="button" onClick={onLogin}>
              {t('auth.registerForm.already_exist')}
            </Button>
          </div>
        </div>
        {captchaVisible && <Captcha onVerify={setCaptchaKey} />}
      </form>
    </AccountBox>
  );
}
