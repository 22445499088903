interface CountryCode {
  country: string;
  code: string;
  shortCode: string;
}

const masks = {
  '+38': '(111) 111 11 11',
  // '+7': '(111) 111 11 11',
  // Казахстан (с пробелом)
  '+7': '(111) 111 11 11',
  // '+375': '(11) 111 11 11',
  '+372': '(1111) 11111',
  '+33': '1 11 11 11 11',
  '+49': '(1111) 111 1111',
  '+996': '(111) 111 111',
  '+371': '(1111) 1111',
  '+370': '(111) 111 11',
  '+373': '(111) 111 11',
  '+48': '(11) 111 11 11',
  '+351': '(111) 111 111',
  '+34': '(111) 111 111',
  '+90': '(111) 111 11 11',
  '+1': '(111) 111 1111',
  // Canada (с пробелом)
  '+1 ': '(111) 111 1111',
  '+998': '(11) 111 1111',
  '+44': '1111 111111',
  '+61': '111 111 111',
  '+40': '(111) 111 111',
  '+39': '(111) 1111 111',
  '+994': '(11) 111 1111',
  '+995': '(11) 111 1111',
  '+374': '(11) 111 111',
  '+421': '(111) 111 111',
  '+32': '(11) 111 11 11',
  '+357': '11 111 111',
};

const countries = {
  '+38': {
    country: 'ukraine',
    code: 'ua',
    shortCode: 'ua',
  },
  /* '+7': {
    country: 'russia',
    code: 'ru',
    shortCode: 'ru',
  }, 
  '+375': {
    country: 'belarus',
    code: 'blr',
    shortCode: 'bl',
  },*/
  '+7': {
    country: 'kazakhstan',
    code: 'kz',
    shortCode: 'kz',
  },
  '+996': {
    country: 'kyrgyzstan',
    code: 'kgz',
    shortCode: 'kg',
  },
  '+998': {
    country: 'uzbekistan',
    code: 'uzb',
    shortCode: 'uz',
  },
  '+1': {
    country: 'usa',
    code: 'usa',
    shortCode: 'us',
  },
  '+48': {
    country: 'poland',
    code: 'pl',
    shortCode: 'pl',
  },
  '+49': {
    country: 'germany',
    code: 'de',
    shortCode: 'de',
  },
  '+370': {
    country: 'lithuania',
    code: 'lt',
    shortCode: 'lt',
  },
  '+371': {
    country: 'latvia',
    code: 'lv',
    shortCode: 'lv',
  },
  '+372': {
    country: 'estonia',
    code: 'ee',
    shortCode: 'ee',
  },
  '+90': {
    country: 'turkey',
    code: 'tr',
    shortCode: 'tr',
  },
  '+351': {
    country: 'portugal',
    code: 'pt',
    shortCode: 'pt',
  },
  '+34': {
    country: 'spain',
    code: 'es',
    shortCode: 'es',
  },
  '+33': {
    country: 'france',
    code: 'fr',
    shortCode: 'fr',
  },
  '+44': {
    country: 'britain',
    code: 'gb',
    shortCode: 'gb',
  },
  '+61': {
    country: 'australia',
    code: 'au',
    shortCode: 'au',
  },
  '+1 ': {
    country: 'canada',
    code: 'ca',
    shortCode: 'ca',
  },
  '+373': {
    country: 'moldova',
    code: 'md',
    shortCode: 'md',
  },
  '+40': {
    country: 'romania',
    code: 'ro',
    shortCode: 'ro',
  },
  '+39': {
    country: 'italy',
    code: 'it',
    shortCode: 'it',
  },
  '+994': {
    country: 'azerbaijan',
    code: 'az',
    shortCode: 'az',
  },
  '+995': {
    country: 'georgia',
    code: 'ge',
    shortCode: 'ge',
  },
  '+374': {
    country: 'armenia',
    code: 'am',
    shortCode: 'am',
  },
  '+421': {
    country: 'slovakia',
    code: 'sk',
    shortCode: 'sk',
  },
  '+32': {
    country: 'belgium',
    code: 'be',
    shortCode: 'be',
  },
  '+357': {
    country: 'cyprus',
    code: 'cy',
    shortCode: 'cy',
  },
};

export const getMask = (code: string): string => masks[code];

export const getMaskLength = (code: string) =>
  getMask(code).replace(/\D+/g, '').length;

export const codes: string[] = Object.keys(masks);

// Will remove `+code`
export const removeCode = (phone: string, code: string) =>
  phone.replace(code, '');

export const getCountryByCode = (code: string) =>
  countries[code] as CountryCode;

export const getCodeByCountry = (country: string): string => {
  const keys = Object.keys(countries);
  for (const key of keys) {
    if (countries[key] && countries[key].code === country.toLowerCase()) {
      return key;
    }
  }
  return '+38';
};
