import React from 'react';
import { Switch } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import { Base as BaseLayout } from '../Layouts';
import { AuthRoutes } from './AuthRoutes';
import { AppRoutes } from './AppRoutes';
import { AppContainer } from 'components/Containers/AppContrainer/Loadable';
import { ERROR_404 } from 'routes/urls';
import { PageNotFound } from 'pages/Error/PageNotFound';

export const Router = (): React.ReactElement => {
  return (
    <BrowserRouter>
      <Switch>
        <AppContainer>
          <BaseLayout>
            <AuthRoutes />
            <AppRoutes />
            <Route path={ERROR_404} component={PageNotFound} />
          </BaseLayout>
        </AppContainer>
      </Switch>
    </BrowserRouter>
  );
};
