import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { setLocale } from 'yup';
import locales, { getLocale } from './locales';

i18n
  .use(
    new LanguageDetector(null, {
      order: [
        'querystring',
        'navigator',
        'cookie',
        'localStorage',
        'sessionStorage',
        'htmlTag',
        'path',
        'subdomain',
      ],
      lookupQuerystring: 'lng',
      lookupCookie: 'appLocale',
      lookupLocalStorage: 'appLocale',
      lookupSessionStorage: 'appLocale',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    }),
  )
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    interpolation: { escapeValue: false },
    defaultNS: 'app',
    fallbackLng: 'en',
    resources: {
      en: { app: locales.en },
      ru: { app: locales.ru },
      uk: { app: locales.uk },
    },
  });

export const locale = () => i18n.language.split('-')[0];

const validationLocale = getLocale(locale()) || undefined;

if (validationLocale) {
  setLocale(validationLocale.validation);
}

export default i18n;
