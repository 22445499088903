import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import * as paths from './paths';
import faker from 'faker';
import { pathToRegexp } from 'path-to-regexp';

const mock: MockAdapter = new MockAdapter(axios, { delayResponse: 2000 });

mock.onPost(paths.AUTH_REGISTER).reply(200, {
  sessionId: faker.datatype.uuid(),
});

mock.onPost(pathToRegexp(paths.AUTH_VERIFY_SESSION)).reply(config => {
  const data = JSON.parse(config.data);
  if (parseInt(data.verification_code) === 111111) {
    return [400, {}];
  }
  return [
    201,
    {
      first_name: faker.name.firstName(),
      last_name: faker.name.lastName(),
      phone: faker.phone.phoneNumber(),
      email: faker.internet.email(),
    },
  ];
});

mock.onPost(paths.INSTANCE_CREATE).reply(config => {
  const data = JSON.parse(config.data);
  if (data.domain === 'somename') {
    return [401, { errorCode: 1001 }];
  }
  return [
    201,
    {
      instance_id: faker.datatype.uuid(),
    },
  ];
});

let instanceTries = 0;

mock.onGet(pathToRegexp(paths.INSTANCE_GET)).reply(config => {
  instanceTries++;

  return [
    200,
    {
      installed: instanceTries > 3,
    },
  ];
});
