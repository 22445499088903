import httpClient from './http.client';
import { AxiosResponse } from 'axios';
import * as paths from './paths';

export const updatePassword = (params: {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}): Promise<AxiosResponse> =>
  httpClient.put(paths.ACCOUNT_CHANGE_PASSWORD, params);

export const activateReferral = (): Promise<AxiosResponse> =>
  httpClient.post(paths.ACCOUNT_ACTIVATE_REFERRAL);

export const getAccountReferrals = (page = 1): Promise<AxiosResponse> =>
  httpClient.get(paths.ACCOUNT_GET_REFERRALS, { params: { page } });

export const getAccountReferralAccruals = (page = 1): Promise<AxiosResponse> =>
  httpClient.get(paths.ACCOUNT_GET_REFERRAL_ACCRUALS, { params: { page } });

export const getAccountReferralPayouts = (page = 1): Promise<AxiosResponse> =>
  httpClient.get(paths.ACCOUNT_GET_REFERRAL_PAYOUTS, { params: { page } });

export const createAccountReferralPayouts = (params): Promise<AxiosResponse> =>
  httpClient.post(paths.ACCOUNT_CREATE_REFERRAL_PAYOUTS, params);

export const getAccountReferralStats = (
  startDate: string | null = null,
  endDate: string | null = null,
): Promise<AxiosResponse> =>
  httpClient.get(paths.ACCOUNT_GET_REFERRAL_STATS, {
    params: { startDate, endDate },
  });
