import React from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

interface Props {
  onVerify: (key: string) => void;
}

export const Captcha = React.memo(({ onVerify }: Props) => {
  return <GoogleReCaptcha onVerify={onVerify} />;
});
