import React from 'react';
import { Tabs } from 'antd';
import { RegistrationsTable, ReferralAccrualsTable } from './components/tables';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getReferralAccrualsAction,
  getReferralsAction,
} from 'store/slices/account/actions';
import { RootState } from 'types/RootState';
import { mapPagination } from '../../../helpers/table';

export function Referrals(): React.ReactElement {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const {
    referrals,
    isLoadingReferrals,
    referralAccruals,
    isLoadingAccruals,
  } = useSelector((state: RootState) => state.account);

  const onReferralsPageChange = (page: number) => {
    dispatch(getReferralsAction.request({ page })).then(() => {});
  };

  const onAccrualsPageChange = (page: number) => {
    dispatch(getReferralAccrualsAction.request({ page })).then(() => {});
  };

  React.useEffect(() => {
    dispatch(getReferralsAction.request({ page: 1 })).then(() => {});
    dispatch(getReferralAccrualsAction.request({ page: 1 })).then(() => {});
  }, [dispatch]);

  return (
    <div className="box">
      <h3>{t('app.account.referrals.title')}</h3>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane
          tab={t('app.account.referrals.tab_registrations')}
          key="registrations"
        >
          <RegistrationsTable
            referrals={referrals.items}
            loading={isLoadingReferrals}
            pagination={mapPagination(referrals, onReferralsPageChange)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t('app.account.referrals.tab_payments')}
          key="payments"
        >
          <ReferralAccrualsTable
            referralAccruals={referralAccruals.items}
            loading={isLoadingAccruals}
            pagination={mapPagination(referralAccruals, onAccrualsPageChange)}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
