import React from 'react';
import { Route } from 'react-router';
import * as urls from 'routes/urls';
import * as pages from 'pages';
import { withAnimation } from '../../pages/withAnimation';

export const AuthRoutes = (): React.ReactElement => {
  return (
    <React.Fragment>
      <Route exact={true} path={urls.INDEX} component={pages.IndexPage} />
      <Route
        exact={true}
        path={urls.AUTH_REGISTER}
        component={withAnimation(pages.Register)}
      />
      <Route
        exact={true}
        path={urls.AUTH_LOGIN}
        component={withAnimation(pages.Login)}
      />
      <Route
        path={urls.AUTH_REGISTER_VERIFICATION}
        component={withAnimation(pages.Verification)}
      />
      <Route
        path={urls.AUTH_FORGOT_PASSWORD}
        component={withAnimation(pages.ForgotPassword)}
      />{' '}
      <Route
        path={urls.AUTH_RESET_PASSWORD}
        component={withAnimation(pages.ResetPassword)}
      />
    </React.Fragment>
  );
};
