import { createPromiseAction } from 'redux-saga-promise-actions';
import {
  ForgotPassword,
  Login,
  Register,
  ResetPassword,
  User,
  VerifySession,
} from './types';

export const validateTokenAction = createPromiseAction('VALIDATE_USER')<
  undefined,
  User,
  any
>();

export const registerAction = createPromiseAction('REGISTER_ACCOUNT')<
  Register,
  { sessionId: string },
  any
>();

export const loginAction = createPromiseAction('LOGIN')<
  Login,
  { token: string },
  any
>();

export const logoutAction = createPromiseAction('LOGOUT')<
  undefined,
  undefined,
  undefined
>();

export const forgotPasswordAction = createPromiseAction('FORGOT_PASSWORD')<
  ForgotPassword,
  any,
  any
>();

export const resetPasswordAction = createPromiseAction('RESET_PASSWORD')<
  ResetPassword,
  any,
  any
>();

export const verifySessionAction = createPromiseAction('VERIFY_SESSION')<
  VerifySession,
  { token: string },
  any
>();

export const getUserCountry = createPromiseAction('CHECK_USER_COUNTRY')<
  undefined,
  { country_code: string },
  any
>();
