import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/RootState';
import { actions } from 'store/slices/instance';
import { message } from 'antd';

interface Props {
  children: React.ReactElement;
}

export function MercureProvider({ children }: Props): React.ReactElement {
  const { account } = useSelector((state: RootState) => state.account);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!account?.id) {
      return;
    }
    console.log(`Initializing mercure listener for user ${account?.id}`);

    const url = new URL(String(process.env.REACT_APP_MERCURE_URL));
    url.searchParams.append('topic', 'accounts/' + account?.id);

    const eventSource = new EventSource(url.toString());

    const handleEvent = (event: any) => {
      if (event.state === 'instance.created') {
        const { pathname } = window.location;
        if (pathname.indexOf('new-instance') === -1) {
          dispatch(actions.markAsCreated(event.instance));
          message.success(
            `CRM ${event.instance.domain} готова к использованию`,
          );
        }
      }

      console.log('Get mercure event: ' + JSON.stringify(event));
    };

    eventSource.onmessage = event => {
      handleEvent(JSON.parse(event.data));
    };
  }, [account?.id, dispatch]);

  return children;
}
