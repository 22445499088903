import { PaginatedState } from './slices/global/types';
import { PayloadAction } from '@reduxjs/toolkit';

export const createPaginatedState = <T>(): PaginatedState<T> => ({
  currentPage: 1,
  total: 0,
  items: [],
  itemsPerPage: 15,
  pageCount: 0,
  meta: null,
});

export const toggleStateLoader = (
  state,
  { payload }: PayloadAction<string>,
) => {
  state[payload] = !state[payload];
};
