/* Main index route */
export const INDEX: string = '/';

export const ERROR_404: string = '/error/page-not-found';

/* Authentication routes */
export const AUTH_LOGIN: string = '/auth/login';
export const AUTH_REGISTER: string = '/auth/register';
export const AUTH_REGISTER_VERIFICATION: string = '/auth/register/verification';
export const AUTH_FORGOT_PASSWORD: string = '/auth/forgot-password';
export const AUTH_RESET_PASSWORD: string = '/auth/reset-password/:token';

/* Application routes */
export const APP_NEW_INSTANCE: string = '/app/new-instance';
export const APP_NEW_INSTANCE_PROGRESS: string =
  '/app/new-instance/:instanceId/progress';

export const APP_ACCOUNT_INDEX: string = '/app/account/dashboard';
export const APP_ACCOUNT_SETTINGS: string = '/app/account/settings';
export const APP_ACCOUNT_REFERRALS: string = '/app/account/referrals';
export const APP_ACCOUNT_PAYOUTS: string = '/app/account/payouts';
export const APP_ACCOUNT_STATISTIC: string = '/app/account/stats';
export const APP_ACCOUNT_RULES: string = '/app/account/rules';
