import { FieldError } from 'react-hook-form';

/**
 * @param {FieldError} rule
 */
export const validationError = (rule?: FieldError) => {
  return rule ? rule.message : undefined;
};

/**
 * @param {FieldError} rule
 */
export const validationStatus = (rule?: FieldError) => {
  return rule ? 'error' : 'success';
};

export const setResponseValidation = (handler, errors: any[]) => {
  errors.forEach(error => {
    const [key] = Object.keys(error);
    handler(key, {
      message: error[key],
    });
  });
};
