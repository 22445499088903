export default {
  base: {
    continue: 'Далее',
    back: 'Назад',
    confirm: 'Подтвердить',
    important: 'Внимание!',
    cancel: 'Отменить',
    add: 'Добавить',
    yes: 'Да',
    empty_list: 'Список пуст',
    save: 'Сохранить',
    back_to_main: 'Вернуться на главную',
  },
  auth: {
    registerForm: {
      email: 'E-mail',
      email_hint: 'Пример: yourmail@gmai.com',
      full_name: 'Ваше имя и фамилия',
      full_name_hint: 'Пример: Андрей Климов',
      phone: 'Номер телефона',
      title: 'Подключитесь сегодня! Полный функционал без оплат до 30 дней',
      already_exist: 'У меня уже есть аккаунт',
      why_email: 'На email будут отправлены данные для входа',
      why_phone: 'На телефон отправится SMS c кодом',
    },
    loginForm: {
      title: 'Вход в аккаунт KeyCRM',
      domain_name: 'Домен компании',
      domain_hint: 'Например: minitoys, megatrading, mebli-torg',
      last_visited_tenants: 'Ранее посещенные CRM:',
      password: 'Пароль',
      login: 'Войти',
      forgotPassword: 'Забыли пароль?',
      rememberMe: 'Запомнить меня',
      noAccount: 'Нет аккаунта?',
      register: 'Зарегистрироваться',
      login_failed: 'Неправильный E-mail или пароль'
    },
    forgotPassword: {
      title: 'Восстановление пароля',
      subTitle: 'Введите ваш E-mail адресс для восстановления пароля',
      toLogin: 'Назад',
      reset: 'Сбросить',
      resetFailed: 'Пользователь с таким E-mail не существует',
      toManyRequests: 'Слишком много неудачных попыток. Пожалуйста, попробуйте позже!'
    },
    resetPassword: {
      title: 'Сброс пароля',
      password: 'Новый пароль',
      passwordConfirmation: 'Подтверждение нового пароля',
      save: 'Сохранить',
      passwordConfirmationFailed: 'Пароли не совпадают',
      success: 'Пароль успешно обновлен! Пожалуйста, выполните вход'
    }
  },
  app: {
    newInstance: {
      title: 'Создаем аккаунт',
      account_name_hint: 'Каким будет адрес входа в KeyCRM<br /> для вашей компании?',
      account_name_example: 'Например: minitoys, megatrading, mebli-torg',
      account_name_requirements: 'Только латинские буквы (от 4 до 20), цифры и дефис',
      account_name_taken: 'Этот домен уже занят',
      confirmation: '<ul style="padding-left: 20px"><li>Вы выбрали <strong>{{domain}}</strong> как название домена.</li> <li>Выбранное название невозможно будет изменить в будущем.</li><li>Вы и Ваши сотрудники будут входить в KeyCRM по ссылке:</li></ul><strong>https://{{domain}}.keycrm.app</strong><br /><br />Вы уверены, что хотите использовать именно это название?',
      register_thank: 'Спасибо, что зарегистировались!',
      account_created: 'Данные для входа в KeyCRM отправлены на Ваш Email',
      account_creation_in_progress: 'Роботы забегали и трудятся создавая для Вас личный кабинет KeyCRM',
      account_enter_now: 'Войти в аккаунт сейчас',
      account_tariff: 'Я ознакомлен(а) с <a href="https://keycrm.app/pricing" target="_blank">тарифами</a>',
      account_tos: 'Регистрируясь, Вы соглашаетесь с <a href="https://keycrm.app/terms-of-use" target="_blank">Условиями использования</a> и <a href="https://keycrm.app/privacy-policy" target="_blank">Политикой конфиденциальности</a>',
      text_spam: 'Если Email не пришел в течении 5 минут - <strong>обязательно</strong> проверьте папку СПАМ',
      text_spam_gmail: '(а также вкладки Реклама, Промоакции и другие в Gmail)',
      or: 'Или',
      go_to_profile: 'Перейти в профиль',
      go_to_crm: 'Перейти в CRM',
      first_steps: 'Пошаговая настройка KeyCRM',
      faq: 'Часто задаваемые вопросы',
      q1: 'Нужно ли привязывать карту к аккаунту? Есть ли автосписание?',
      a1: 'Нет, не нужно. Мы вообще не требуем привязки и не сохраняем данные Ваших карт.',
      q2: 'Когда я должен буду заплатить?',
      a2: 'Оплата списывается с Вашего внутренного баланса в KeyCRM по факту использования, каждого 1-ого числа месяца. Оплату Вы можете сделать в любое удобное для Вас время. Также можно сделать оплату заранее, чтобы система не заблокировалась при отрицательном балансе.',
      q3: 'Как я могу заходить в KeyCRM?',
      a3: 'Для входа в KeyCRM Вам нужен интернет и любой браузер (Chrome, Opera, Firefox, Microsoft Edge) - на компьютере или на телефоне. Также есть отдельное мобильное приложение.',
      q4: 'Будут ли мои данные в безопасности?',
      a4: 'Что касается сохранности данных - они хранятся в надежном европейском дата-центре. Также у нас настроено регулярное резервное копирование данных.<br /> По поводу неразглашения Вашей информации - согласно <a href="https://keycrm.app/terms-of-use" target="_blank" rel="noopener noreferrer" >Пользовательскому соглашению</a> мы не имеем права разглашать информацию наших клиентов.',
    },
    account: {
      navigation: {
        logout: 'Выйти',
        profile: 'Мой профиль',
        referrals: 'Рефералы',
        payments: 'Выплаты',
        stats: 'Статистика',
        rules: 'Правила',
      },
      instances: {
        title: 'Доступные CRM',
        name: 'Название',
        role: 'Роль',
        balance: 'Баланс',
        remove: 'Удалить',
        enter: 'Вход'
      },
      add_existing: {
        title: 'Добавить существующую CRM',
        subtitle: 'Чтобы добавить существующую CRM-систему в этот аккаунт необходимо',
        rule_1: 'Выполнить вход в CRM, которую хотите добавить в свой аккаунт',
        rule_2: 'Внутри CRM перейти в Мой профиль → Настройки',
        rule_3: 'Скопировать код вашей CRM и вставить его в поле ниже',
        code: 'Код CRM-системы',
        enter_code: 'Введите код',
        msg_success: 'CRM успешно добавленна в ваш аккаунт',
        msg_exists: 'CRM уже добавленна в ваш аккаунт',
        msg_error: 'Ошибка добавления CRM. Пожалуйста, попробуйте еще раз',
      },
      create_new: {
        button_text: 'Создать новую CRM',
        title: 'Новая CRM',
        subtitle: 'Создайте адрес входа в KeyCRM для вашей компании',
      },
      deleting:{
        title: 'CRM, запланированные к удалению',
        confirmation: 'Будут удалены все данные по заказам, заявкам, покупателям и другие данные из CRM <strong>{{domain}}</strong>. Возможность входа будет заблокирована. Вы действительно хотите удалить эту CRM?',
        input_message: 'Для подтверждения введите: <strong>{{domain}}</strong>'
      },
      settings: {
        title: 'Данные аккаунта',
        title_password: 'Пароль аккаунта',
        full_name: 'Имя и Фамилия',
        oldPassword: 'Текущий пароль',
        password: 'Новый пароль',
        passwordConfirmation: 'Подтверждение пароля',
        passwordConfirmationFailed: 'Пароли не совпадают'
      },
      referrals: {
        title: 'Рефералы',
        tab_registrations: 'Регистрации',
        tab_payments: 'Оплаты',
        table_date: 'Дата регистрации',
        table_user: 'Пользователь',
        table_phone: 'Телефон',
        table_count: 'Кол-во CRM',
        table_crm_name: 'Название CRM',
        table_sum: 'Сумма оплаты',
        table_profit: 'Прибыль',
        table_payment_date: 'Дата оплаты',
        table_status: 'Статус',
        table_status_active: 'Активный',
        table_status_pending: 'Ожидание',
      },
      payouts: {
        title: 'История выплат',
        payout_stat: 'Статистика выплат',
        payout_total: 'Всего начислено',
        payout_paid: 'Выплачено',
        payout_left: 'Остаток на счету',
        payout_request: 'Запрос на вывод средств',
        payout_request_hint: 'Укажите реквизиты для выплаты (карта или счет ФЛП)',
        table_date: 'Дата создания',
        table_amount: 'Сумма',
        table_comment: 'Коментарий',
        table_status: 'Статус',
        table_payout_date: 'Дата выплаты',
        messages: {
          created: 'Запрос на вывод средств успешно создан. Пожалуйста ожидайте ответ от менеджера'
        },
      },
      stats: {
        title: 'Статистика реферальной ссылки',
        button: 'Показать',
        date_from: 'Дата от',
        date_to: 'Дата до',
        table_date: 'Дата',
        table_visits: 'Переходы',
        table_registrations: 'Регистрации',
      },
    },
    referralBanner: {
      promo: 'Приглашайте новых пользователей и получайте до 50% от их оплат в течении 12 месяцев',
      read_more: 'Узнать больше',
      link: 'Ваша реферальная ссылка',
    }
  },
  verification: {
    title: 'Введите код подтверждения из SMS сообщения',
    failed: 'Код подтверждения введен неверно',
    countdown_finished: 'Пожалуйста, обратитесь в чат',
  },
  validation: {
    mixed: {
      required: '${path} - обязательное поле',
    },
    string: {
      email: '${path} адрес введен не корректно', // don't provide a long messages!!!
      length: '${path} должен состоять из ${length} цифр',
      min: '${path} должен быть не менее ${min} символов'
    },
  },
  validation_rules: {
    unique_email: 'E-mail адрес уже используеться',
    unique_phoneNumber: 'Номер телефона уже используеться',
  },
  countries: {
    ukraine: 'Украина',
    // russia: 'Россия',
    // belarus: 'Беларусь',
    usa: 'США',
    poland: 'Польша',
    germany: 'Германия',
    lithuania: 'Литва',
    latvia: 'Латвия',
    estonia: 'Естония',
    kazakhstan: 'Казахстан',
    kyrgyzstan: 'Киргизия',
    uzbekistan: 'Узбекистан',
    portugal: 'Португалия',
    turkey: 'Турция',
    spain: 'Испания',
    france: 'Франция',
    britain: 'Великобритания',
    canada: 'Канада',
    australia: 'Австралия',
    moldova: 'Молдова',
    romania: 'Румыния',
    italy: 'Италия',
    azerbaijan: 'Азербайджан',
    georgia: 'Грузия',
    armenia: 'Армения',
    slovakia: 'Словакия',
    belgium: 'Бельгия',
    cyprus: 'Кипр',
  },
  errors: {
    500: 'Произошла ошибка, пожалуйста попробуйте позже',
    429: 'Слишком много запросов, пожалуйста попробуйте позже',
    session_expired: 'Время сессии истекло, пожалуйста авторизуйтесь снова',
  }
};
