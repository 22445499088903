import { createPromiseAction } from 'redux-saga-promise-actions';
import { CreatingInstance, Instance, FetchInstances } from './types';
import { PaginationResponse } from '../global/types';

export const createInstanceAction = createPromiseAction('CREATE_INSTANCE')<
  CreatingInstance,
  Instance,
  any
>();

export const getInstancesAction = createPromiseAction('GET_INSTANCE')<
  FetchInstances,
  PaginationResponse<Instance>,
  any
>();

export const deleteInstanceAction = createPromiseAction('DELETE_INSTANCE')<
  { id: string },
  any,
  any
>();

export const revertInstanceAction = createPromiseAction('REVERT_INSTANCE')<
  { id: string },
  Instance,
  any
>();

export const linkInstanceAction = createPromiseAction('LINK_INSTANCE')<
  { code: string },
  Instance,
  any
>();
