import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'types/RootState';
import { PasswordsForm } from './PasswordsForm';
import { formatPhone } from 'helpers/account';
import { Account } from 'store/slices/account/types';

export function Settings(): React.ReactElement {
  const [t] = useTranslation();
  const { account } = useSelector((state: RootState) => state.account);
  const formattedPhone = React.useMemo(() => formatPhone(account as Account), [
    account,
  ]);

  return (
    <div className="account__main">
      <div className="account__right">
        <div className="box">
          <h3>{t('app.account.settings.title')}</h3>
          <div className="account-info">
            <span>{t('app.account.settings.full_name')}</span>
            <div>
              {account?.firstName} {account?.lastName}
            </div>
            <span>Email</span>
            <div>{account?.email}</div>
            <span>Phone number</span>
            <div>{formattedPhone}</div>
          </div>

          <br />
          <br />
          <PasswordsForm />
        </div>
      </div>
    </div>
  );
}
