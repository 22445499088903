import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import Cookies from 'js-cookie';
import { RegisterForm } from './components/RegisterForm/RegisterForm';
import * as paths from 'routes/urls';
import { Helmet } from 'react-helmet-async';
import './styles.scss';

export function Register() {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const handleSubmit = (): void => {
    const type = new URLSearchParams(location.search.substring(1)).get('type');
    const redirectTo =
      type === 'partner' ? paths.APP_ACCOUNT_INDEX : paths.APP_NEW_INSTANCE;

    sessionStorage.setItem('verificationRedirect', redirectTo);
    history.push(paths.AUTH_REGISTER_VERIFICATION, {
      from: paths.AUTH_REGISTER,
      next: redirectTo,
    });
  };

  const handleLogin = (): void => {
    history.push(paths.AUTH_LOGIN, {
      from: paths.AUTH_REGISTER,
    });
  };

  React.useEffect(() => {
    console.log('Init ref: ', Cookies.get('keycrm_ref_code'));
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('auth.registerForm.title')}</title>
      </Helmet>
      <RegisterForm onSubmit={handleSubmit} onLogin={handleLogin} />
    </React.Fragment>
  );
}
