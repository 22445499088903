import React from 'react';
import { resetPasswordCheck } from 'api/auth';
import { message } from 'antd';
import { useHistory, useParams, useLocation } from 'react-router';
import { AUTH_LOGIN } from 'routes/urls';
import { Helmet } from 'react-helmet-async';
import { ResetPasswordForm } from './components/ResetPasswordForm';
import { useTranslation } from 'react-i18next';

export function ResetPassword(): React.ReactElement {
  const history = useHistory();
  const [t] = useTranslation();
  const { search } = useLocation();
  const { token } = useParams<{ token: string }>();

  const [isLinkValid, setIsLinkValid] = React.useState<boolean>(false);

  const email = React.useMemo(() => new URLSearchParams(search).get('email'), [
    search,
  ]);

  const onReset = () => {
    message.success(t('auth.resetPassword.success'));
    history.push(AUTH_LOGIN);
  };

  React.useEffect(() => {
    resetPasswordCheck(token, String(email))
      .then(() => setIsLinkValid(true))
      .catch(() => {
        history.push(AUTH_LOGIN);
        message.warn('Ссылка для сброса пароля больше не доступна');
      });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('auth.resetPassword.title')}</title>
      </Helmet>
      {isLinkValid && (
        <ResetPasswordForm
          token={token}
          email={String(email)}
          onReset={onReset}
        />
      )}
    </React.Fragment>
  );
}
