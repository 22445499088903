import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form as AntForm, Input, message } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import AccountBox from 'components/Containers/AccountBox';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormTitle } from 'components/Form/FormTitle/FormTitle';
import * as validation from 'helpers/validation';
import { AUTH_LOGIN } from 'routes/urls';
import { forgotPasswordAction } from 'store/slices/auth/actions';
import { RootState } from 'types/RootState';
import { rules } from './rules';

interface Props {
  onReset(): void;
}

export function ForgotPasswordForm({ onReset }: Props) {
  const [t] = useTranslation();
  const validationRules = rules(t);
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state.auth.isForgotPassLoading,
  );
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationRules),
  });

  const onSubmit = value => {
    dispatch(forgotPasswordAction.request(value))
      .then(() => {
        reset();
        onReset();
      })
      .catch(e => {
        if (e.response.status === 400) {
          message.error(t('auth.forgotPassword.resetFailed'));
        } else if (e.response.status === 429) {
          message.error(t('auth.forgotPassword.toManyRequests'));
        }
      });
  };

  return (
    <AccountBox image="laptop">
      <form
        className={'ant-form ant-form-vertical accountbox__form'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="accountbox__form-inner">
          <FormTitle>{t('auth.forgotPassword.title')}</FormTitle>
          <span>{t('auth.forgotPassword.subTitle')}</span>
          <AntForm.Item
            label={t('auth.registerForm.email')}
            help={validation.validationError(errors.email)}
            validateStatus={validation.validationStatus(errors.email)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="email"
                  type="email"
                  allowClear={true}
                  autoComplete={'off'}
                />
              )}
            />
          </AntForm.Item>

          <Button
            block
            type="primary"
            loading={loading}
            htmlType="submit"
            className="form-button"
          >
            {t('auth.forgotPassword.reset')}
          </Button>
          <div className="margin-top-one">
            <span style={{ fontSize: '12px' }}>
              <Link
                to={{
                  pathname: AUTH_LOGIN,
                }}
              >
                {t('auth.forgotPassword.toLogin')}
              </Link>
            </span>
          </div>
        </div>
      </form>
    </AccountBox>
  );
}
