import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Form as AntForm, Input, message } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import AccountBox from 'components/Containers/AccountBox';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormTitle } from 'components/Form/FormTitle/FormTitle';
import * as validation from 'helpers/validation';
import { AUTH_FORGOT_PASSWORD, AUTH_REGISTER } from 'routes/urls';
import { loginAction } from 'store/slices/auth/actions';
import { RootState } from 'types/RootState';
import { rules } from './rules';
import './styles.scss';

interface Props {
  onLogin(): void;
}

export function LoginForm({ onLogin }: Props) {
  const [t] = useTranslation();
  const validationRules = rules(t);
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.auth.isLoginLoading);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationRules),
  });

  const onSubmit = value => {
    dispatch(loginAction.request(value))
      .then(onLogin)
      .catch(e => {
        if (e.response.status === 401) {
          message.error(t('auth.loginForm.login_failed'));
        }
      });
  };

  return (
    <AccountBox image="laptop">
      <form
        className={'ant-form ant-form-vertical accountbox__form'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="accountbox__form-inner">
          <FormTitle>{t('auth.loginForm.title')}</FormTitle>
          <AntForm.Item
            label={t('auth.registerForm.email')}
            help={validation.validationError(errors.email)}
            validateStatus={validation.validationStatus(errors.email)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="email"
                  type="email"
                  allowClear={true}
                  autoComplete={'off'}
                />
              )}
            />
          </AntForm.Item>
          <AntForm.Item
            label={t('auth.loginForm.password')}
            help={validation.validationError(errors.password)}
            validateStatus={validation.validationStatus(errors.password)}
          >
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input.Password
                  {...field}
                  id="password"
                  allowClear={true}
                  autoComplete={'off'}
                />
              )}
            />
          </AntForm.Item>
          <div className="additional-block margin-bottom-one">
            <Checkbox>{t('auth.loginForm.rememberMe')}</Checkbox>
            <Button
              type="link"
              htmlType="button"
              onClick={() => history.push(AUTH_FORGOT_PASSWORD)}
            >
              {t('auth.loginForm.forgotPassword')}
            </Button>
          </div>
          <Button
            block
            type="primary"
            loading={loading}
            htmlType="submit"
            className="form-button"
          >
            {t('auth.loginForm.login')}
          </Button>
          <div className="margin-top-one">
            <span style={{ fontSize: '12px' }}>
              {t('auth.loginForm.noAccount')} &nbsp;
              <span>
                <Link
                  to={{
                    pathname: AUTH_REGISTER,
                  }}
                >
                  {t('auth.loginForm.register')}
                </Link>
              </span>
            </span>
          </div>
        </div>
      </form>
    </AccountBox>
  );
}
