import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AccountState,
  Account,
  ReferralAccrual,
  ReferralPayout,
} from './types';
import { createPaginatedState, toggleStateLoader } from '../../helpers';
import {
  activateReferralAction,
  createReferralPayoutAction,
  getReferralAccrualsAction,
  getReferralPayoutsAction,
  getReferralsAction,
} from './actions';
import { PaginationResponse } from '../global/types';

const initialState: AccountState = {
  account: null,
  isUpdatingPassword: false,
  isLoadingReferrals: false,
  isLoadingAccruals: false,
  isLoadingPayouts: false,
  isCreatingPayout: false,
  isActivatingReferral: false,
  referrals: createPaginatedState(),
  referralAccruals: createPaginatedState(),
  referralPayouts: createPaginatedState(),
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccount(state, { payload }: PayloadAction<Account>) {
      state.account = payload;
    },
    toggleStateLoader,
  },
  extraReducers: {
    [activateReferralAction.success.toString()](
      state,
      { payload: { refNumber } }: PayloadAction<{ refNumber: string }>,
    ) {
      state.account = {
        ...state.account,
        refNumber,
      } as Account;
    },
    [getReferralsAction.success.toString()](
      state,
      { payload }: PayloadAction<PaginationResponse<Account>>,
    ) {
      state.referrals = payload;
    },
    [getReferralAccrualsAction.success.toString()](
      state,
      { payload }: PayloadAction<PaginationResponse<ReferralAccrual>>,
    ) {
      state.referralAccruals = payload;
    },
    [getReferralPayoutsAction.success.toString()](
      state,
      { payload }: PayloadAction<PaginationResponse<ReferralPayout>>,
    ) {
      state.referralPayouts = payload;
    },
    [createReferralPayoutAction.success.toString()](
      state,
      { payload }: PayloadAction<ReferralPayout>,
    ) {
      state.referralPayouts.items.push(payload);
    },
  },
});

export const { actions } = slice;
