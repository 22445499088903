import { put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions';
import {
  createInstanceAction,
  deleteInstanceAction,
  getInstancesAction,
  linkInstanceAction,
  revertInstanceAction,
} from './actions';
import { actions } from './';
import * as api from 'api/instances';
import { ACTIVE_INSTANCES, SCHEDULED_FOR_DELETE } from './types';

const loaderName = (actionType: string) => {
  return {
    [ACTIVE_INSTANCES]: 'isActiveLoading',
    [SCHEDULED_FOR_DELETE]: 'isDeletingLoading',
  }[actionType];
};

function* doCreateInstance(
  action: ReturnType<typeof createInstanceAction.request>,
) {
  try {
    yield put(actions.toggleStateLoader('creatingLoading'));
    const { data } = yield api.createInstance(action.payload);
    yield put(createInstanceAction.success(data));
    resolvePromiseAction(action, data);
  } catch (err) {
    console.error(err);
    yield put(createInstanceAction.failure(err));
    rejectPromiseAction(action, err);
  } finally {
    yield put(actions.toggleStateLoader('creatingLoading'));
  }
}

function* doFetchInstances(
  action: ReturnType<typeof getInstancesAction.request>,
) {
  const loader = loaderName(action.payload.type);
  try {
    yield put(actions.toggleStateLoader(loader));
    const { page, type } = action.payload;
    const { data } = yield api.getInstances(page, type);
    yield put(getInstancesAction.success({ ...data, type }));
    resolvePromiseAction(action, data);
  } catch (err) {
    console.error(err);
    yield put(getInstancesAction.failure(err));
    rejectPromiseAction(action, err);
  } finally {
    yield put(actions.toggleStateLoader(loader));
  }
}

function* doDeleteInstance(
  action: ReturnType<typeof deleteInstanceAction.request>,
) {
  try {
    yield api.deleteInstance(action.payload.id);
    const { data } = yield api.getInstance(action.payload.id);
    yield put(deleteInstanceAction.success(data));
    resolvePromiseAction(action, data);
  } catch (err) {
    console.error(err);
    yield put(deleteInstanceAction.failure(err));
    rejectPromiseAction(action, err);
  }
}
function* doRevertInstance(
  action: ReturnType<typeof revertInstanceAction.request>,
) {
  try {
    const { data } = yield api.revertInstance(action.payload.id);
    yield put(revertInstanceAction.success(data));
    resolvePromiseAction(action, data);
  } catch (err) {
    console.error(err);
    yield put(revertInstanceAction.failure(err));
    rejectPromiseAction(action, err);
  }
}
function* doLinkInstance(
  action: ReturnType<typeof linkInstanceAction.request>,
) {
  try {
    yield put(actions.toggleStateLoader('isLinkingLoading'));
    const { data } = yield api.linkInstance(action.payload.code);
    yield put(linkInstanceAction.success(data));
    resolvePromiseAction(action, data);
  } catch (err) {
    console.error(err);
    yield put(linkInstanceAction.failure(err));
    rejectPromiseAction(action, err);
  } finally {
    yield put(actions.toggleStateLoader('isLinkingLoading'));
  }
}

export function* instanceSaga() {
  yield takeLatest(createInstanceAction.request, doCreateInstance);
  yield takeEvery(getInstancesAction.request, doFetchInstances);
  yield takeLatest(deleteInstanceAction.request, doDeleteInstance);
  yield takeLatest(revertInstanceAction.request, doRevertInstance);
  yield takeLatest(linkInstanceAction.request, doLinkInstance);
}
