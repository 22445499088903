import React from 'react';
import DomainInput from '../DomainInput';
import { useDispatch } from 'react-redux';
import { Checkbox, Modal } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { isReservedWord } from 'utils/reserved-words';
import { createInstanceAction } from 'store/slices/instance/actions';
import { Instance } from 'store/slices/instance/types';

interface Props {
  hintTitle: string;
  onToggleCheckbox: (value: boolean) => void;
  onSuccess: (instance: Instance) => void;
}

const confirmationModal = (t: TFunction, domain: string, onOk: () => void) => {
  const content = (
    <span
      dangerouslySetInnerHTML={{
        __html: t('app.newInstance.confirmation', {
          domain,
        }),
      }}
    />
  );
  return Modal.confirm({
    title: t('base.important'),
    okText: t('base.yes'),
    cancelText: t('base.cancel'),
    // @ts-ignore
    icon: <ExclamationCircleOutlined />,
    content,
    onOk,
  });
};

function Form(
  { hintTitle, onToggleCheckbox, onSuccess }: Props,
  formRef,
): React.ReactElement {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [domain, setDomain] = React.useState<string>('');
  const [isExist, setIsExist] = React.useState<boolean>(false);
  const [isReserved, setIsReserved] = React.useState<boolean>(false);
  const [isFirstClicked, setIsFirstClicked] = React.useState<boolean>(false);
  const [isCheckboxChecked, setCheckboxChecked] = React.useState<boolean>(true);

  React.useImperativeHandle(formRef, () => ({
    toggleConfirmation() {
      toggleConfirmation();
    },
    reset() {
      reset();
    },
  }));

  const onConfirm = (domain: string): void => {
    dispatch(createInstanceAction.request({ domain }))
      .then(onSuccess)
      .catch(onFailed);
  };

  const onFailed = error => {
    if (error.response.data.message.indexOf('already in use') !== -1) {
      setIsExist(true);
    }
  };

  const toggleConfirmation = (): void => {
    setIsFirstClicked(true);
    if (isReservedWord(domain)) {
      return setIsReserved(true);
    }
    isReserved && setIsReserved(false);
    isValid() &&
      confirmationModal(t, domain, () => {
        onConfirm(domain);
      });
  };

  const reset = () => {
    setDomain('');
    setIsReserved(false);
    setIsExist(false);
    setIsFirstClicked(false);
  };

  const onCheckboxChanged = (value: boolean) => {
    setCheckboxChecked(value);
    onToggleCheckbox(value);
  };

  const isValid = () => {
    return domain.length > 3 && /^[a-z0-9-]*$/.test(domain.toLowerCase());
  };

  return (
    <React.Fragment>
      <p
        className="margin-bottom-two"
        dangerouslySetInnerHTML={{
          __html: hintTitle,
        }}
      />
      <DomainInput value={domain} onChange={setDomain} />
      <div className="ant-row ant-form-item ant-form-item-with-help">
        <div className="ant-col ant-form-item-control">
          <div className="ant-form-item-explain ant-form-item-explain-success">
            <div role="alert">{t('app.newInstance.account_name_example')}</div>
          </div>
        </div>
      </div>
      {!isValid() && isFirstClicked && (
        <div className="alert">
          {t('app.newInstance.account_name_requirements')}
        </div>
      )}
      {isValid() && (isExist || isReserved) && (
        <div className="text-red">
          {t('app.newInstance.account_name_taken')}
        </div>
      )}
      <div className="margin-top-two padding-left-halve">
        <Checkbox
          checked={isCheckboxChecked}
          onChange={({ target: { checked } }) => onCheckboxChanged(checked)}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t('app.newInstance.account_tariff'),
            }}
          />
        </Checkbox>
      </div>
    </React.Fragment>
  );
}

export const NewInstanceForm = React.forwardRef(Form);
