import Cookies from 'js-cookie';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions';
import {
  forgotPasswordAction,
  getUserCountry,
  loginAction,
  logoutAction,
  registerAction,
  resetPasswordAction,
  validateTokenAction,
  verifySessionAction,
} from './actions';
import { actions } from './';
import * as api from 'api/auth';
import { RootState } from 'types/RootState';
import { getCountryByCode } from 'utils/phone-mask';

const splitFullName = (full_name: string) => {
  const [first_name, last_name] = full_name.split(' ');

  return {
    first_name,
    last_name,
  };
};

function* doLogin(action: ReturnType<typeof loginAction.request>) {
  try {
    yield put(actions.toggleStateLoader('isLoginLoading'));
    const { data } = yield api.login(action.payload);
    yield put(loginAction.success(data));
    resolvePromiseAction(action, data);
  } catch (e) {
    console.error(e);
    yield put(loginAction.failure(e));
    rejectPromiseAction(action, e);
  } finally {
    yield put(actions.toggleStateLoader('isLoginLoading'));
  }
}

function* doLogout(action: ReturnType<typeof logoutAction.request>) {
  try {
    const { data } = yield api.logout();
    yield put(logoutAction.success());
    resolvePromiseAction(action, data);
  } catch (e) {
    console.error(e);
    yield put(logoutAction.failure());
    rejectPromiseAction(action, e);
  }
}

function* doValidateToken(
  action: ReturnType<typeof validateTokenAction.request>,
) {
  const { authToken } = yield select((state: RootState) => state.auth);
  if (authToken === undefined) {
    rejectPromiseAction(action, new Error('Token not provided'));
    return yield put(actions.setIsTokenVerified(true));
  }
  try {
    const { data } = yield api.validateTokenRequest();
    yield put(validateTokenAction.success(data));
    resolvePromiseAction(action, data);
  } catch (e) {
    console.error(e);
    yield put(validateTokenAction.failure(e));
    rejectPromiseAction(action, e);
  } finally {
    yield put(actions.setIsTokenVerified(true));
  }
}

function* doRegisterAccount(action: ReturnType<typeof registerAction.request>) {
  try {
    const { selectedPhoneCode } = yield select(state => state.auth);
    yield put(actions.toggleStateLoader('isRegisterLoading'));
    const requestParams = {
      ...splitFullName(action.payload.fullName),
      ...action.payload,
      phoneNumber: action.payload.phoneNumber.replace('+', ''),
      country: getCountryByCode(selectedPhoneCode).shortCode,
      refNumber: Cookies.get('keycrm_ref_code'),
    };
    const { data } = yield api.registerRequest(requestParams);
    yield put(registerAction.success(data));
    resolvePromiseAction(action, data);
  } catch (e) {
    console.error(e);
    yield put(registerAction.failure(e));
    rejectPromiseAction(action, e);
  } finally {
    yield put(actions.toggleStateLoader('isRegisterLoading'));
  }
}

function* doVerifySession(
  action: ReturnType<typeof verifySessionAction.request>,
) {
  try {
    yield put(actions.toggleStateLoader('isSessionLoading'));
    const { data } = yield api.verifySession(action.payload);
    yield put(verifySessionAction.success(data));
    resolvePromiseAction(action, data);
  } catch (e) {
    console.error(e);
    yield put(verifySessionAction.failure(e));
    rejectPromiseAction(action, e);
  } finally {
    yield put(actions.toggleStateLoader('isSessionLoading'));
  }
}

function* doForgotPassword(
  action: ReturnType<typeof forgotPasswordAction.request>,
) {
  try {
    yield put(actions.toggleStateLoader('isForgotPassLoading'));
    const { data } = yield api.forgotPassword(action.payload.email);
    yield put(forgotPasswordAction.success(data));
    resolvePromiseAction(action, data);
  } catch (e) {
    console.error(e);
    yield put(forgotPasswordAction.failure(e));
    rejectPromiseAction(action, e);
  } finally {
    yield put(actions.toggleStateLoader('isForgotPassLoading'));
  }
}

function* doResetPassword(
  action: ReturnType<typeof resetPasswordAction.request>,
) {
  try {
    yield put(actions.toggleStateLoader('isResetPassLoading'));
    const { data } = yield api.resetPassword(
      action.payload.token,
      action.payload.email,
      action.payload.password,
      action.payload.passwordConfirmation,
    );
    yield put(resetPasswordAction.success(data));
    resolvePromiseAction(action, data);
  } catch (e) {
    console.error(e);
    yield put(resetPasswordAction.failure(e));
    rejectPromiseAction(action, e);
  } finally {
    yield put(actions.toggleStateLoader('isResetPassLoading'));
  }
}

export function* doGetUserCountry() {
  const ipData = sessionStorage.getItem('ipData');
  if (ipData !== null) {
    return yield put(getUserCountry.success(JSON.parse(ipData)));
  }
  try {
    const { data } = yield api.getUserCountry();
    yield put(getUserCountry.success(data));
    sessionStorage.setItem('ipData', JSON.stringify(data));
  } catch (e) {
    console.error(e);
    yield put(getUserCountry.failure(e));
  }
}

export function* authSaga() {
  yield takeLatest(loginAction.request, doLogin);
  yield takeLatest(logoutAction.request, doLogout);
  yield takeLatest(validateTokenAction.request, doValidateToken);
  yield takeLatest(registerAction.request, doRegisterAccount);
  yield takeLatest(verifySessionAction.request, doVerifySession);
  yield takeLatest(forgotPasswordAction.request, doForgotPassword);
  yield takeLatest(resetPasswordAction.request, doResetPassword);
  yield takeLatest(getUserCountry.request, doGetUserCountry);
}
