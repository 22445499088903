export default {
  base: {
    continue: 'Continue',
    back: 'Back',
    confirm: 'Confirm',
    important: 'Warning!',
    cancel: 'Cancel',
    add: 'Add',
    yes: 'Yes',
    empty_list: 'Empty list',
    save: 'Save',
    back_to_main: 'Back to the dashboard',
  },
  auth: {
    registerForm: {
      email: 'E-mail',
      email_hint: 'Example: yourmail@gmai.com',
      full_name: 'Your First and Last Name',
      full_name_hint: 'Example: John Doe',
      phone: 'Phone number',
      title: 'Register today! Full access for free up to 30 days',
      already_exist: 'I already have an account',
      why_email: 'We will send login info to this email',
      why_phone: 'We will send SMS with confirmation code',
    },
    loginForm: {
      title: 'Login to KeyCRM account',
      domain_name: 'Company account',
      domain_hint: 'Example: minitoys, megatrading, fur-sale',
      last_visited_tenants: 'Previously used accounts:',
      password: 'Password',
      login: 'Login',
      forgotPassword: 'Forgot password?',
      rememberMe: 'Remember me',
      noAccount: 'No account?',
      register: 'Register',
      login_failed: 'Invalid E-mail or password'
    },
    forgotPassword: {
      title: 'Forgot password',
      subTitle: 'Enter e-mail to recover access',
      toLogin: 'Back',
      reset: 'Reset',
      resetFailed: 'Account with this E-mail not exist',
      toManyRequests: 'To many failed requests. Please try again later'
    },
    resetPassword: {
      title: 'Reset your password',
      password: 'New password',
      passwordConfirmation: 'New password confirmation',
      save: 'Save',
      passwordConfirmationFailed: 'Passwords don\'t match',
      success: 'Password updated! Please, authorize'
    }
  },
  app: {
    newInstance: {
      title: 'Creating an account',
      account_name_hint: 'Which address would you like to have for your company?',
      account_name_example: 'Example: minitoys, megatrading, fur-sale',
      account_name_requirements: 'Only latin chars (4 to 20), digits and dash allowed',
      account_name_taken: 'This account already taken',
      confirmation: '<ul style="padding-left: 20px"><li>You have chosen <strong>{{domain}}</strong> as domain name.</li> <li>It\'ll not be possible to change it in future.</li><li>You and your employees will enter KeyCRM using next link:</li></ul><strong>https://{{domain}}.keycrm.app</strong><br /><br />Are you sure, you would like to use this name?',
      register_thank: 'Thank you for registration!',
      account_created: 'Login info for KeyCRM sent to your Email',
      account_creation_in_progress: 'Robots working making up KeyCRM account for you',
      account_enter_now: 'Enter an account now',
      account_tariff: 'I\'m aware of <a href="https://keycrm.app/pricing" target="_blank">tariffs</a>',
      account_tos: 'I\'m agree with <a href="https://keycrm.app/terms-of-use" target="_blank">Terms of use</a> and <a href="https://keycrm.app/privacy-policy" target="_blank">Privacy policy</a>',
      text_spam: 'In case you didn\'t get an email during 5 minutes, please, <strong>check your SPAM folder</strong>',
      text_spam_gmail: '(Promotions and other Gmail tabs too)',
      or: 'Or',
      go_to_profile: 'Enter profile',
      go_to_crm: 'Go to CRM',
      first_steps: 'Step-by-step setup of KeyCRM',
      faq: 'Frequently Asked Questions',
      q1: 'Is it necessary to link a card to the account? Is there auto-debiting?',
      a1: 'No, it is not necessary. We do not require linking and do not store your card data.',
      q2: 'When should I make a payment?',
      a2: 'Payment is deducted from your internal balance in KeyCRM based on usage, on the 1st of each month. You can make a payment at any time convenient for you. You can also make a payment in advance to prevent the system from being blocked in case of a negative balance.',
      q3: 'How can I access KeyCRM?',
      a3: 'To access KeyCRM, you need the internet and any browser (Chrome, Opera, Firefox, Microsoft Edge) - on a computer or on a phone. There is also a separate mobile app.',
      q4: 'Will my data be secure?',
      a4: 'Regarding data security - it is stored in a reliable European data center. We also have regular data backup configured.<br /> Regarding the non-disclosure of your information - according to the <a href="https://keycrm.app/terms-of-use" target="_blank" rel="noopener noreferrer" >User Agreement</a> we do not have the right to disclose our clients\' information.',
    },
    account: {
      navigation: {
        logout: 'Logout',
        profile: 'My profile',
        referrals: 'Referrals',
        payments: 'Payouts',
        stats: 'Statistics',
        rules: 'Rules',
      },
      instances: {
        title: 'Available CRMs',
        name: 'Name',
        role: 'Role',
        balance: 'Balance',
        remove: 'Remove',
        enter: 'Enter'
      },
      add_existing: {
        title: 'Add existing CRM',
        subtitle: 'To add existing CRM into this account you need:',
        rule_1: 'Login into CRM, which you want to add',
        rule_2: 'Inside CRM go to My profile → Settings',
        rule_3: 'Copy Code and paste it here',
        code: 'CRM Code',
        enter_code: 'Enter code',
        msg_success: 'CRM successfully added to account',
        msg_exists: 'This CRM was already added before',
        msg_error: 'Error adding CRM. Please, try again',
      },
      create_new: {
        button_text: 'Create new CRM',
        title: 'New CRM',
        subtitle: 'Put new address for your KeyCRM company',
      },
      deleting: {
        title: 'CRMs to be deleted',
        confirmation: 'All leads, orders, buyers and entire data will be removed from <strong>{{domain}}</strong> CRM. Access will be blocked. Are you sure you want to remove it?',
        input_message: 'Enter the following to confirm: <strong>{{domain}}</strong>'
      },
      settings: {
        title: 'Account information',
        title_password: 'Account password',
        full_name: 'First and Last name',
        oldPassword: 'Current password',
        password: 'New password',
        passwordConfirmation: 'Password confirmation',
        passwordConfirmationFailed: 'Passwords don\'t match'
      },
      referrals: {
        title: 'Referrals',
        tab_registrations: 'Registrations',
        tab_payments: 'Payments',
        table_date: 'Registration date',
        table_user: 'User',
        table_phone: 'Phone',
        table_count: 'CRM Amount',
        table_crm_name: 'CRM name',
        table_sum: 'Payment sum',
        table_profit: 'Profit',
        table_payment_date: 'Payment date',
        table_status: 'Status',
        table_status_active: 'Active',
        table_status_pending: 'Pending',
      },
      payouts: {
        title: 'Payouts',
        payout_stat: 'Payouts stat',
        payout_total: 'Total',
        payout_paid: 'Paid out',
        payout_left: 'To be paid',
        payout_request: 'Request money',
        payout_request_hint: 'Enter credentials for payout',
        table_date: 'Create date',
        table_amount: 'Amount',
        table_comment: 'Comment',
        table_status: 'Status',
        table_payout_date: 'Date of payment',
        messages: {
          created: 'Request successfully created. Please wait for manager confirmation'
        },
      },
      stats: {
        title: 'Referral link statistics',
        button: 'Show',
        date_from: 'Date from',
        date_to: 'Date to',
        table_date: 'Date',
        table_visits: 'Visits',
        table_registrations: 'Registrations',
      },
    },
    referralBanner: {
      promo: 'Invite new users and get up to 50% from their payments during 12 months',
      read_more: 'Find out more',
      link: 'Your referral link',
    },
  },
  verification: {
    title: 'Enter confirmation code from SMS',
    failed: 'Code is incorrect',
    countdown_finished: 'Please, write to our chat',
  },
  validation: {
    mixed: {
      required: '${path} - required field',
    },
    string: {
      email: '${path} is not valid', // don't provide a long messages!!!
      length: '${path} should consist of ${length} digits',
      min: '${path} should be at least ${min} chars'
    },
  },
  validation_rules: {
    unique_email: 'Email is already in use',
    unique_phoneNumber: 'Phone number is in use',
  },
  countries: {
    ukraine: 'Ukraine',
    // russia: 'Russia',
    // belarus: 'Belarus',
    usa: 'USA',
    poland: 'Poland',
    germany: 'Germany',
    lithuania: 'Lithuania',
    latvia: 'Latvia',
    estonia: 'Estonia',
    kazakhstan: 'Kazakhstan',
    kyrgyzstan: 'Kyrgyzstan',
    uzbekistan: 'Uzbekistan',
    turkey: 'Turkey',
    portugal: 'Portugal',
    spain: 'Spain',
    france: 'France',
    britain: 'Great Britain',
    canada: 'Canada',
    australia: 'Australia',
    moldova: 'Moldova',
    romania: 'Romania',
    italy: 'Italy',
    azerbaijan: 'Azerbaijan',
    georgia: 'Georgia',
    armenia: 'Armenia',
    slovakia: 'Slovakia',
    belgium: 'Belgium',
    cyprus: 'Cyprus',
  },
  errors: {
    500: 'An error occurred, please try again',
    429: 'Too many requests, please try again later',
    session_expired: 'Session expired, please login again',
  }
};
