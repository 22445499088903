import React from 'react';
import { Select } from 'antd';
import MaskedInput from 'antd-mask-input';
import { ControllerRenderProps } from 'react-hook-form';
import Flag from 'react-world-flags';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import {
  codes,
  getCountryByCode,
  getMask,
  removeCode,
} from '../../../utils/phone-mask';

interface Props extends ControllerRenderProps {
  defaultCode: string;
  onCodeChanged?: (string) => void;
  disabled?: boolean;
}

const renderSelectOption = (code: string, t: TFunction): React.ReactElement => {
  const countryOpts = getCountryByCode(code);
  return (
    <Select.Option value={code} key={code}>
      <span
        style={{
          marginRight: '5px',
        }}
      >
        <Flag code={countryOpts.code} height="20" width="20" />
      </span>
      <span style={{ width: '35px', display: 'inline-block' }}>{code}</span>{' '}
      {t(`countries.${countryOpts.country}`)}
    </Select.Option>
  );
};

export default function PhoneNumberInput({
  disabled,
  defaultCode,
  onChange,
  onCodeChanged,
  onBlur,
  value,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const [codeValue, setCodeValue] = React.useState(defaultCode);
  const [inputValue, setInputValue] = React.useState('');

  const mask = React.useMemo(() => getMask(codeValue), [codeValue]);
  const defaultValue = React.useMemo(() => removeCode(value, defaultCode), [
    value,
    defaultCode,
  ]);

  const onCodeChange = (value: string) => {
    setCodeValue(value);
    onChange(value + inputValue);
    onCodeChanged && onCodeChanged(value);
  };

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(codeValue + event.target.value);
    setInputValue(event.target.value);
  };

  const sortedCountries = codes.sort((a, b) => {
    return t('countries.' + getCountryByCode(a).country) <
      t('countries.' + getCountryByCode(b).country)
      ? -1
      : 1;
  });

  React.useEffect(() => {
    setCodeValue(defaultCode);
  }, [defaultCode]);

  return (
    <MaskedInput
      id="phone"
      name="phone"
      mask={mask}
      onBlur={onBlur}
      disabled={disabled}
      onChange={onPhoneChange}
      defaultValue={defaultValue}
      className={'phone-input'}
      autoComplete={'disabled'}
      addonBefore={
        <Select
          value={codeValue}
          disabled={disabled}
          className="select-after"
          onChange={onCodeChange}
          dropdownClassName="phone-number-drop-down"
        >
          {sortedCountries.map(code => renderSelectOption(code, t))}
        </Select>
      }
    />
  );
}
