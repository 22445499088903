import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import FormTitle from 'components/Form/FormTitle';
import { generatePath, useHistory } from 'react-router';
import { RootState } from 'types/RootState';
import { APP_ACCOUNT_INDEX, APP_NEW_INSTANCE_PROGRESS } from 'routes/urls';
import AccountBox from 'components/Containers/AccountBox';
import { NewInstanceForm } from 'components/Form/NewInstanceForm';
import { Instance } from 'store/slices/instance/types';
import events from 'utils/events';
import './styles.scss';

export function NewInstance(): React.ReactElement {
  const [t] = useTranslation();
  const history = useHistory();
  const ref = React.useRef<any>();
  const { creatingLoading } = useSelector((state: RootState) => state.instance);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);

  const handleConfirm = () => {
    const {
      current: { toggleConfirmation },
    } = ref;
    toggleConfirmation();
  };

  const onSuccess = ({ id, domain }: Instance) => {
    events.registered(domain);
    history.push(
      generatePath(APP_NEW_INSTANCE_PROGRESS, {
        instanceId: id,
      }),
    );
  };

  React.useEffect(() => {
    events.verified();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('app.newInstance.title')}</title>
      </Helmet>
      <AccountBox image="delivery">
        <div className="accountbox__form">
          <div className="accountbox__form-inner">
            <FormTitle>{t('app.newInstance.title')}</FormTitle>
            <NewInstanceForm
              ref={ref}
              onSuccess={onSuccess}
              onToggleCheckbox={setIsDisabled}
              hintTitle={t('app.newInstance.account_name_hint')}
            />
            <div className="margin-top-one">
              <Button
                block
                type="primary"
                htmlType="submit"
                className="form-button"
                loading={creatingLoading}
                disabled={!isDisabled}
                onClick={handleConfirm}
              >
                {t('base.continue')}
              </Button>
            </div>
            <div
              className="margin-top-one"
              style={{ fontSize: '80%' }}
              dangerouslySetInnerHTML={{
                __html: t('app.newInstance.account_tos'),
              }}
            />
            <div className="margin-top-one" style={{ fontSize: '80%' }}>
              <div>{t('app.newInstance.or')}</div>
              <Link
                to={{
                  pathname: APP_ACCOUNT_INDEX,
                }}
              >
                {t('app.newInstance.go_to_profile')}
              </Link>
            </div>
          </div>
        </div>
      </AccountBox>
    </React.Fragment>
  );
}
